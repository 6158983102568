import React, {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  useCallback,
} from "react";
import {
  FilterState,
  FilterContextProviderType,
  FilterActionType,
  FilterFunctions,
} from "./types";
import { reducer } from "./utils";
import { useCurrentView } from "../../routes/currentView";
import { useQueryParams } from "../../utils/useQueryParams";

const DEFAULT_FILTER = {
  filters: {
    products: { active: [], default: [] },
    collections: { active: [], default: [] },
  },
} as FilterState;

const FilterContext = createContext([
  DEFAULT_FILTER,
  null as any,
  null as any,
] as FilterContextProviderType);

const useFilterContextCreator = (): FilterContextProviderType => {
  const { isGoods } = useCurrentView();
  const query = useQueryParams();

  const [filterState, dispatch] = useReducer(reducer, DEFAULT_FILTER);

  const onSingleChange: FilterFunctions["onSingleChange"] = (
    id,
    mode,
    filterKey
  ) => {
    dispatch({
      type: FilterActionType.HANDLE_SINGLE_CHANGE,
      payload: { id, mode, filterKey },
    });
  };

  const onMultiChange: FilterFunctions["onMultiChange"] = (
    id,
    mode,
    filterKey
  ) => {
    dispatch({
      type: FilterActionType.HANDLE_MULTI_CHANGE,
      payload: { id, mode, filterKey },
    });
  };

  const initFilters: FilterFunctions["initFilters"] = useCallback(
    (mode, filters) => {
      dispatch({
        type: FilterActionType.INIT_FILTERS,
        payload: { mode, filters, query },
      });
    },
    [dispatch, query]
  );

  const reset: FilterFunctions["reset"] = (mode) => {
    dispatch({ type: FilterActionType.RESET, payload: { mode } });
  };

  const getActiveFilters: FilterFunctions["getActiveFilters"] = (mode) =>
    filterState.filters[mode].active;

  const functions = {
    onSingleChange,
    onMultiChange,
    initFilters,
    reset,
    getActiveFilters,
  };

  return [filterState, dispatch, functions];
};

export const FilterContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const provider = useFilterContextCreator();
  return (
    <FilterContext.Provider value={provider}>{children}</FilterContext.Provider>
  );
};

export const useFilterContext = (): FilterContextProviderType => {
  const service = useContext(FilterContext);

  if (!service) {
    throw new Error("Filter Context is unavailable");
  }

  return service;
};

export const withFilterContext = (Component: any) => {
  return function WithFilterContext(props: any) {
    const service = useFilterContext();

    return <Component {...props} FilterContext={service} />;
  };
};
