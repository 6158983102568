import React, { FC, ReactNode, useEffect } from "react";
//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import "./styles/global.css";
import { StyleContextProvider } from "./styles/style.context";
import { QueryClient, QueryClientProvider } from "react-query";
import { FilterContextProvider } from "./features/filters/filter.context";

const queryClient = new QueryClient();

export const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    smoothscroll.polyfill();
  });

  return (
    <BrowserRouter>
      <StyleContextProvider>
        <QueryClientProvider client={queryClient}>
          <FilterContextProvider>
            <Provider store={store}>{children}</Provider>
          </FilterContextProvider>
        </QueryClientProvider>
      </StyleContextProvider>
    </BrowserRouter>
  );
};
