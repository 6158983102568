import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../../../../api/products";
import { selectChosenGoods } from "../../../../../../../app/state/chosenGoodsSlice";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";
import { LikeButton } from "../likeButton/LikeButton";
import { SubCategory } from "../subCategory/SubCategory";

const Wrapper = styled.div`
  width: 316px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.big {
    width: 416px;
  }
  &:hover {
    & > div > div.background {
      background-color: rgb(0, 0, 0, 0.3);
    }
  }
  ${media.tablet`
    width: 342px;
    &.big {
      width: 342px;
    } 
  `}
  ${media.phone`
    width: 100%;
    &.big {
      width: 100%;
    } 
  `}
`;
const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 28px;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.3s linear;
  background-color: transparent;
  z-index: 2;
`;
const Image = styled.img`
  width: 100%;
  height: 316px;
  position: relative;
  z-index: 1;
  &.big {
    height: 416px;
  }

  ${media.tablet`
    height: 342px;
    &.big {
      height: 342px;
    } 
  `}
  ${media.phone`
    width: 100%;
    &.big {
      width: 100%;
    } 
  `}
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > span.size {
    width: max-content;
  }
`;
const SubCategoriesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const Title = styled(Text)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DataItem = ({
  data,
  dataItemOnClick,
  addItemToChosenGoods,
  removeItemFromChosenGoods,
  isBigItemSize,
  titleColor,
  className,
}: {
  data: GoodType;
  dataItemOnClick: () => void;
  addItemToChosenGoods: () => void;
  removeItemFromChosenGoods: () => void;
  isBigItemSize: boolean;
  titleColor?: string;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { white1, badgeDark, black } = theme.colors;
  const { id, image, name, dimensions, types } = data;
  const chosenGoods = useSelector(selectChosenGoods);
  const isChosen =
    chosenGoods.findIndex((item: GoodType) => item.id === id) !== -1;

  return (
    <Wrapper
      className={`${isBigItemSize ? "big" : ""} ${className}`}
      onClick={dataItemOnClick}
    >
      <ImageWrapper>
        <Background className={"background"} />
        <Image
          src={image}
          alt={id}
          className={`${isBigItemSize ? "big" : ""}`}
        />
        <LikeButton
          onClick={isChosen ? removeItemFromChosenGoods : addItemToChosenGoods}
          isChoosen={isChosen}
        />
      </ImageWrapper>
      <Container>
        {types && (
          <SubCategoriesWrapper>
            {types.map((item: { id: string; name: string }, index: number) => {
              const { id } = item;
              return (
                <SubCategory
                  key={id}
                  data={item}
                  className={index === types.length - 1 ? "last" : ""}
                />
              );
            })}
          </SubCategoriesWrapper>
        )}

        <Title
          type={TextType.TITLE_H2}
          color={titleColor ? titleColor : isBigItemSize ? white1 : black}
        >
          {name}
        </Title>
        <Text
          className={"size"}
          type={TextType.TEXT_16_REGULAR}
          color={badgeDark}
          margin={"16px 0 0 0"}
        >{`${
          dimensions ? `${dimensions[0]} x ${dimensions[1]} см` : "N/A"
        }`}</Text>
      </Container>
    </Wrapper>
  );
};
