import styled, { useTheme } from "styled-components";
import { CollectionType } from "../../../../../api/collections";
import { CommonButton } from "../../../../../components/commonButton/CommonButton";
import { LoaderBlock } from "../../../../../components/loaderBlock/LoaderBlock";
import { Text, TextType } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { CollectionItem } from "./components/collectionItem/CollectionItem";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
  position: relative;
  ${media.desktop`
    padding: 0 24px;
  `}
`;
const List = styled.ul<any>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  padding: 0;
  margin-bottom: ${(props: any) =>
    props.isLoadMoreButtonDisplayed ? "0" : "240px"};

  & > div > div > span {
    color: ${(props: any) => props.theme.colors.black};
    &:hover {
      color: ${(props: any) => props.theme.colors.black};
    }
  }
  ${media.desktop`
    display: flex;
    flex-wrap: wrap;
  `}
  ${media.tablet`
    justify-content: space-between;
  `}
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 88px 0 240px 0;

  ${media.tablet`
    margin: 80px 0 120px 0;
  `}
`;
const Title = styled(Text)`
  ${media.desktop`
    font-size: 40px;
    line-height: 48px;
  `}
`;

export const OtherCollections = ({
  list,
  isLoadingOtherCollectionsInProgress,
  isLoadMoreButtonDisplayed,
  loadMoreOnClick,
  otherItemOnClick,
}: {
  list: CollectionType[];
  isLoadingOtherCollectionsInProgress: boolean;
  isLoadMoreButtonDisplayed: boolean;
  loadMoreOnClick: () => void;
  otherItemOnClick: (id: string) => void;
}) => {
  const theme: any = useTheme();
  const { black, white, badgeMiddle } = theme.colors;

  return (
    <Wrapper>
      <Title type={TextType.TITLE_H1} color={black} margin={"0 0 32px 0"}>
        {"Другие коллекции"}
      </Title>
      <List isLoadMoreButtonDisplayed={isLoadMoreButtonDisplayed}>
        {list ? (
          list.map((item: CollectionType) => {
            const { id } = item;
            return (
              <CollectionItem
                key={id}
                data={item}
                collectionItemOnClick={() => {
                  otherItemOnClick(id);
                }}
              />
            );
          })
        ) : (
          <Text type={TextType.TITLE_H1} color={badgeMiddle}>
            {"Нет данных"}
          </Text>
        )}
      </List>
      {isLoadMoreButtonDisplayed && (
        <ButtonWrapper>
          <CommonButton
            value={"ЗАГРУЗИТЬ ЕЩЕ"}
            onClick={loadMoreOnClick}
            width={"416px"}
            backgroundColor={"transparent"}
            color={black}
            borderColor={black}
            backgroundColorHover={black}
            borderColorHover={black}
            colorHover={white}
            isLoadingInProcess={isLoadingOtherCollectionsInProgress}
          />
        </ButtonWrapper>
      )}
      {isLoadingOtherCollectionsInProgress && <LoaderBlock />}
    </Wrapper>
  );
};
