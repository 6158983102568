import styled, { useTheme } from "styled-components";
import { SpinnerCircle } from "../../features/animations/SpinnerCircle";

const Background = styled.div`
  position: absolute;
  z-index: 9999999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(2px);
`;

export interface ComponentProps {
  withoutLoader?: boolean;
}

export const LoaderBlock = ({
  withoutLoader = false,
}: {
  withoutLoader?: boolean;
}) => {
  const theme: any = useTheme();

  return (
    <Background>
      {!withoutLoader && <SpinnerCircle backgroundColor={theme.colors.black} />}
    </Background>
  );
};
