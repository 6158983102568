import styled from "styled-components";
import { media } from "../../../../../styles/media";
import backgroundImage from "../../assets/main_image.png";
import { EscortPopup } from "./components/escortPopup/EscortPopup";

const Wrapper = styled.div<any>`
  width: 100%;
  height: 760px;
  box-sizing: border-box;
  padding: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-image: ${(props: any) => `url(${props.backgroundImage})`};
  background-size: cover;
  background-position: center;

  ${media.tabletS`
     padding: 64px 24px;
  `}
`;

export const Escort = () => {
  return (
    <Wrapper backgroundImage={backgroundImage}>
      <EscortPopup />
    </Wrapper>
  );
};
