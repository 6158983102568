import styled, { useTheme } from "styled-components";
import { media } from "../../styles/media";
import { TextType, Text } from "../text/Text";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 204px;
  ${media.tablet`
    display: none;
  `}
`;
const PageIndicator = styled(Text)<any>`
  pointer-events: ${(props: any) =>
    props.pageIndicatorOnClick ? "auto" : "none"};

  &:hover {
    color: ${(props: any) =>
      props.pageIndicatorOnClick
        ? props.theme.colors.badgeMiddle
        : props.theme.colors.badgeDark};
  }
`;

export const Crumbs = ({
  chapter,
  pageIndicator,
  name,
  pageIndicatorOnClick,
  ...props
}: {
  chapter: string;
  pageIndicator: string;
  name?: string;
  pageIndicatorOnClick?: () => void;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { badgeDark } = theme.colors;
  const pageLabel =
    pageIndicator === "tiles"
      ? "Плитка"
      : pageIndicator === "discounts"
      ? "Акции"
      : pageIndicator === "plumbing"
      ? "Сантехника"
      : pageIndicator === "furniture"
      ? "Мебель"
      : pageIndicator === "light"
      ? "Свет"
      : pageIndicator === "kitchen"
      ? "Кухни"
      : "";

  return (
    <Wrapper {...props}>
      <Text
        type={TextType.TEXT_12_REGULAR}
        color={badgeDark}
      >{`${chapter} / `}</Text>
      <PageIndicator
        type={TextType.TEXT_12_REGULAR}
        color={badgeDark}
        margin={"0 0 0 3px"}
        pageIndicatorOnClick={!!pageIndicatorOnClick}
        onClick={() => {
          pageIndicatorOnClick && pageIndicatorOnClick();
        }}
      >{` ${pageLabel}`}</PageIndicator>
      {name && (
        <Text
          type={TextType.TEXT_12_REGULAR}
          color={badgeDark}
          margin={"0 0 0 3px"}
        >{`/ ${name}`}</Text>
      )}
    </Wrapper>
  );
};
