import React from "react";
import { useCurrentView } from "../../routes/currentView";
import { View } from "../../routes";
import { useGetBrands } from "../../api/brands";
import { Helmet } from "react-helmet";
import { useQueryParams } from "../../utils/useQueryParams";

export const DynamicTitle = () => {
  const { currentView } = useCurrentView();
  const brandsList = useGetBrands(0, () => {});
  const queryParams = useQueryParams();

  console.log(brandsList, queryParams?.brand);

  const mapping = {
    [View.PRODUCTS]: ({
      queryParams,
    }: {
      queryParams: Record<string, string | string[]>;
    }) =>
      !!queryParams?.brand
        ? brandsList.find((brand: any) => brand.id === queryParams.brand)
            ?.name || "Products"
        : "Products",
    [View.COLLECTIONS]: ({
      queryParams,
    }: {
      queryParams: Record<string, string | string[]>;
    }) =>
      !!queryParams?.brand
        ? brandsList.find((brand: any) => brand.id === queryParams.brand)
            ?.name || "Products"
        : "Products",
  };

  return (
    <Helmet>
      <title>
        {" "}
        {(mapping as any)[currentView]
          ? (mapping as any)[currentView]({ queryParams })
          : "Positano"}
      </title>
    </Helmet>
  );
};
