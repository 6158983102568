import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../consts";
import { useLocation } from "react-router-dom";
import { parse, stringifyUrl } from "query-string";
import { useApi } from "../features/apiBuilder";

export interface GoodType {
  id: string;
  areasOfUse: string[];
  articleId: string;
  brandId: string;
  category: string;
  collectionId: string;
  colors: { name: string; hex: string }[];
  dimensions: number[];
  discountValue: number;
  format: string;
  image: string;
  isAvailable: boolean;
  name: string;
  readableProperties: { objectId: string; label: string; value: string }[];
  section: string;
  structure: string;
  surfaceType: string;
  url: string;
  types: {
    id: string;
    name: string;
  }[];
}

const GET_PRODUCTS_KEYWORD = "GET_PRODUCTS_KEYWORD";

export const useGetProductsList = (section: string, limit: number) => {
  const { search } = useLocation();
  const url = stringifyUrl({
    url: `${API_URL}/products`,
    query: { section, limit, ...parse(search) },
  });

  const [localData, , isLoading] = useApi({
    url,
    keywords: [GET_PRODUCTS_KEYWORD],
    transformResponse: ({ payload }) => payload,
  });

  return { products: (localData || []) as GoodType[], isLoading };
};

export const useGetSimilarProducts = (
  id: string,
  limit: number,
  setIsLoadingDataInProcess?: (value: boolean) => void
) => {
  const [localData, setLocalData] = useState<GoodType[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/products/similar/${id}?limit=${limit}`)
      .then((res) => {
        const { data } = res;
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        setLocalData(data.payload);
      })
      .catch((error) => {
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        console.log("error: ", error);
      });
  }, [id, limit]);

  return localData;
};

export const useGetProductData = (
  id: string,
  setIsLoadingDataInProcess?: (value: boolean) => void
) => {
  const [localData, setLocalData] = useState<any>({});

  useEffect(() => {
    axios
      .get(`${API_URL}/products/${id}`)
      .then((res) => {
        const { data } = res;
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        setLocalData(data.payload);
      })
      .catch((error) => {
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        console.log("error: ", error);
      });
  }, [id]);

  return localData;
};
