import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  addChosenGood,
  removeChosenGood,
} from "../../../app/state/chosenGoodsSlice";
import { Crumbs } from "../../../components/crumbs/Crumbs";
import { PageWrapper } from "../../general/contacts/Contacts";
import { CenteredWrapper } from "../../general/main/Main";
import { General } from "../../../layouts/general/General";
import { View } from "../../../routes/routeInterfaces";
import { media } from "../../../styles/media";
import { useCustomHistory } from "../../../utils/react-router-dom-abstraction";
import { CollectionInfo } from "./components/collectionInfo/CollectionInfo";
import { CollectionProductsList } from "./components/collectionProductsList/CollectionProductsList";
import {
  useGetCollectionData,
  useGetOtherCollections,
} from "../../../api/collections";
import { OtherCollections } from "./components/otherCollections/OtherCollections";
import { GoodType } from "../../../api/products";
import {
  selectIsLoadingDataInProcess,
  setIsLoadingData,
} from "../../../app/state/goodsSlice";

const Container = styled(PageWrapper)`
  overflow-x: hidden;
  position: relative;
  ${media.desktop`
    padding: 80px 0 0 0;
  `}
`;
const Wrapper = styled(CenteredWrapper)`
  max-width: 1376px;
  box-sizing: border-box;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  padding: 24px 0 0 0;
  ${media.desktop`
    border: none;
  `}
`;
const StyledCrumbs = styled(Crumbs)`
  padding: 0 32px;
  ${media.desktop`
    display: none;
  `}
`;

export const CollectionDetails = ({ match }: { match: any }) => {
  const currentPath = match.path;
  const pathParams = match.params;
  const dispatch = useDispatch();
  const { id } = pathParams;
  const [otherCollectionsLimit, setOtherCollectionsLimit] = useState(3);
  const [
    isLoadingOtherCollectionsInProgress,
    setIsLoadingOtherColletionsInProgress,
  ] = useState(false);
  const isLoadingDataInProcess = useSelector(selectIsLoadingDataInProcess);
  const setIsLoadingDataInProcess = (value: boolean) => {
    dispatch(setIsLoadingData({ value }));
  };

  const data = useGetCollectionData(id, setIsLoadingDataInProcess);
  const history = useCustomHistory();
  const toDetails = View.PRODUCTS_DETAILS;
  const { name, section, products } = data;
  const otherCollections = useGetOtherCollections(
    id,
    otherCollectionsLimit,
    setIsLoadingOtherColletionsInProgress
  );

  const isLoadMoreButtonDisplayed =
    otherCollections.length > 3 &&
    otherCollections.length - otherCollectionsLimit > 0;

  const dataItemOnClick = (id: string) => {
    history.push(toDetails, { pathParams: { id } });
  };
  const addItemToChosenGoods = (item: GoodType) => {
    dispatch(addChosenGood({ value: item }));
  };
  const removeItemFromChosenGoods = (id: string) => {
    dispatch(removeChosenGood({ id }));
  };
  const loadMoreOnClick = () => {
    setIsLoadingOtherColletionsInProgress(true);
    setTimeout(() => {
      setOtherCollectionsLimit(otherCollectionsLimit + 3);
      setIsLoadingOtherColletionsInProgress(false);
    }, 3000);
  };
  const otherItemOnClick = (id: string) => {
    history.push(View.COLLECTIONS_DETAILS, { pathParams: { id } });
  };

  useEffect(() => {
    if (!isLoadingDataInProcess) {
      setIsLoadingDataInProcess(true);
    }
    if (!isLoadingOtherCollectionsInProgress) {
      setIsLoadingOtherColletionsInProgress(true);
    }
  }, []);

  return (
    <General currentPath={currentPath}>
      <Container>
        <Wrapper>
          <StyledCrumbs
            chapter={"Каталог"}
            pageIndicator={section}
            name={name}
          />
          <CollectionInfo
            data={data}
            isLoadingDataInProcess={isLoadingDataInProcess}
          />
          <CollectionProductsList
            data={products}
            dataItemOnClick={dataItemOnClick}
            addItemToChosenGoods={addItemToChosenGoods}
            removeItemFromChosenGoods={removeItemFromChosenGoods}
            isLoadingDataInProcess={isLoadingDataInProcess}
          />
          <OtherCollections
            list={otherCollections}
            isLoadingOtherCollectionsInProgress={
              isLoadingOtherCollectionsInProgress
            }
            isLoadMoreButtonDisplayed={isLoadMoreButtonDisplayed}
            loadMoreOnClick={loadMoreOnClick}
            otherItemOnClick={otherItemOnClick}
          />
        </Wrapper>
      </Container>
    </General>
  );
};
