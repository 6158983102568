import styled from "styled-components";
import { media } from "../../../../../styles/media";

const ImagesWrapper = styled.div<any>`
  width: calc(100% - 448px);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  &.desktop {
    display: none;
  }

  ${media.desktop`
    &.monitor {
        display: none;
    }
    &.desktop {
        display: flex;
        width: 100%;
        height: 488px;
        padding-left: 24px;
    }
  `}
`;
const TransferWrapper = styled.div<any>`
  width: 100%;
  display: flex;
  gap: 32px;
  transform: ${(props: any) => `translateX(-${props.translateValue}px)`};
  transition: all 0.3s linear;
`;
const Image = styled.img`
  width: 640px;
  height: 700px;
  ${media.tabletS`
    width: calc(100% - 24px);
    height: 488px;
  `}
`;
const Logo = styled.img`
  width: 200px;
  height: 100px;
  border: 1px solid;
  position: absolute;
  top: 24px;
  left: 48px;
`;

export const ImagesCarousel = ({
  imagesArr,
  translateValue,
  logo,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  ...props
}: {
  imagesArr: any[];
  translateValue: number;
  logo?: string;
  handleTouchStart?: (e: any) => void;
  handleTouchMove?: (e: any) => void;
  handleTouchEnd?: () => void;
  className?: string;
}) => {
  return (
    <ImagesWrapper {...props}>
      <TransferWrapper translateValue={translateValue}>
        {imagesArr.map((item: string, index: number) => (
          <Image
            src={item}
            key={index}
            onTouchStart={(e: any) => {
              handleTouchStart && handleTouchStart(e);
            }}
            onTouchMove={(e: any) => {
              handleTouchMove && handleTouchMove(e);
            }}
            onTouchEnd={handleTouchEnd}
          />
        ))}
      </TransferWrapper>
      {logo && <Logo src={logo} alt={"Logo"} />}
    </ImagesWrapper>
  );
};
