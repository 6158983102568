import { CollectionDetails } from "../containers/collections/collectionDetails/collectionDetails";
import { Collections } from "../containers/collections/Collections";
import { Brands } from "../containers/general/brands/Brands";
import { Contacts } from "../containers/general/contacts/Contacts";
import { Cooperation } from "../containers/general/cooperation/Cooperation";
import { Main } from "../containers/general/main/Main";
import { Products } from "../containers/products/Products";
import { ProductsDetails } from "../containers/products/productsDetails/ProductsDetails";
import DefaultPage from "./DefaultPage";
import { PATHS } from "./paths";

export const routes = [
  {
    path: PATHS.MAIN,
    component: Main,
  },
  { path: PATHS.PRODUCTS, component: Products },
  { path: PATHS.PRODUCTS_DETAILS, component: ProductsDetails },
  { path: PATHS.COLLECTIONS, component: Collections },
  { path: PATHS.COLLECTIONS_DETAILS, component: CollectionDetails },
  {
    path: PATHS.CONTACTS,
    component: Contacts,
  },
  {
    path: PATHS.BRANDS,
    component: Brands,
  },
  {
    path: PATHS.COOPERATION,
    component: Cooperation,
  },
  {
    path: PATHS.REDIRECT,
    component: DefaultPage,
  },
];
