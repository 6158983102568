import styled from "styled-components";
import { media } from "../../../../styles/media";

export interface PageOptionType {
  name: string;
  id: string;
  isDisabled?: boolean;
}

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0;
  margin: 0 0 82px 0;
  ${media.tablet`
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 0 64px 24px;
    padding: 0 24px 0 0;
  `}
`;

const Item = styled.li`
  width: max-content;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 32px;
  border-radius: 24px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  background-color: transparent;
  color: ${(props: any) => props.theme.colors.black};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    background-color: ${(props: any) => props.theme.colors.black};
    color: ${(props: any) => props.theme.colors.white1};
  }

  &.last {
    margin: 0;
  }
  &.active {
    background-color: ${(props: any) => props.theme.colors.black};
    color: ${(props: any) => props.theme.colors.white1};
    pointer-events: none;
  }
  &.disabled {
    pointer-events: none;
    background-color: ${(props: any) => props.theme.colors.white};
    color: ${(props: any) => props.theme.colors.badgeMiddle};
    border: 1px solid;
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  &:hover > li > span {
    visibility: visible;
    transition: all 0.3s;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: ${(props: any) => props.theme.colors.black};
  color: ${(props: any) => props.theme.colors.white};
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  position: absolute;
  top: -40px;
  left: 15px;
  z-index: 1;
  opacity: 0.5;
  transition: none;
`;

export const PageOptions = ({
  arr,
  currentSection,
  pageOptionOnClick,
}: {
  arr: PageOptionType[];
  currentSection: string;
  pageOptionOnClick: (id: string) => void;
}) => {
  return (
    <Wrapper>
      {arr.map((item: PageOptionType, index: number) => {
        const { id, name, isDisabled } = item;
        const activeClassSection = currentSection === id ? ["active"] : [];
        const lastClassSection = arr.length - 1 === index ? ["last"] : [];
        const disabledClassSection = !!isDisabled ? ["disabled"] : [];
        const classNames = [
          ...activeClassSection,
          ...lastClassSection,
          ...disabledClassSection,
        ];
        return (
          <ItemWrapper>
            <Item
              key={id}
              className={classNames.join(" ")}
              onClick={() => {
                pageOptionOnClick(id);
              }}
            >
              {name}
              {!!isDisabled && <Tooltip>Уже скоро</Tooltip>}
            </Item>
          </ItemWrapper>
        );
      })}
    </Wrapper>
  );
};
