// @ts-ignore
import styled from "styled-components";
import { media } from "../../../../../../../styles/media";
import { ImageWrapper, Wrapper } from "../../BrandsItem";

const Container = styled(Wrapper)`
  padding: 0;
  ${media.tablet`
    border: none;
  `}
`;
const ImagePlaceholder = styled(ImageWrapper)`
  height: 240px;
  border-bottom: none;
  ${media.tablet`
    height: 120px;
  `}
`;

export const Tail = () => {
  return (
    <Container>
      <ImagePlaceholder />
    </Container>
  );
};
