import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../../../../api/products";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";
import { DataItem } from "../dataItem/DataItem";
import { SeeAll } from "../seeAll/SeeAll";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 32px;
  &.right {
    justify-content: flex-end;
  }
  ${media.desktop`
    display: flex;
    flex-wrap: wrap;
  `}
  ${media.tablet`
    justify-content: space-between;
    &.right {
      justify-content: flex-start;
    }
  `}
`;

export const DataList = ({
  data,
  dataItemOnClick,
  addItemToChosenGoods,
  removeItemFromChosenGoods,
  showAllDiscounts,
  withBigItems,
  dataItemTitleColor,
  ...props
}: {
  data: GoodType[];
  dataItemOnClick: (id: string) => void;
  addItemToChosenGoods: (item: GoodType) => void;
  removeItemFromChosenGoods: (id: string) => void;
  showAllDiscounts?: () => void;
  withBigItems?: boolean;
  dataItemTitleColor?: string;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle } = theme.colors;
  const isBigItemSize = !!showAllDiscounts || !!withBigItems;
  const preparedArr =
    !!showAllDiscounts && data.length > 0
      ? data.length === 1
        ? [...data, { id: "showAll" }]
        : [...data.slice(0, 2), { id: "showAll" }, ...data.slice(2)]
      : data;

  return (
    <>
      {preparedArr.length > 0 ? (
        <Wrapper {...props}>
          {preparedArr.map((item: any, index: number) => {
            const { id } = item;
            if (!!showAllDiscounts) {
              const isShowAll = data.length === 1 ? index === 1 : index === 2;
              return isShowAll ? (
                <SeeAll key={id} onClick={showAllDiscounts} />
              ) : (
                <DataItem
                  key={id}
                  data={item}
                  dataItemOnClick={() => {
                    dataItemOnClick(id);
                  }}
                  addItemToChosenGoods={() => {
                    addItemToChosenGoods(item);
                  }}
                  removeItemFromChosenGoods={() => {
                    removeItemFromChosenGoods(id);
                  }}
                  isBigItemSize={isBigItemSize}
                  titleColor={dataItemTitleColor}
                />
              );
            } else {
              return (
                <DataItem
                  key={id}
                  data={item}
                  dataItemOnClick={() => {
                    dataItemOnClick(id);
                  }}
                  addItemToChosenGoods={() => {
                    addItemToChosenGoods(item);
                  }}
                  removeItemFromChosenGoods={() => {
                    removeItemFromChosenGoods(id);
                  }}
                  isBigItemSize={isBigItemSize}
                  titleColor={dataItemTitleColor}
                />
              );
            }
          })}
        </Wrapper>
      ) : (
        <Text type={TextType.TITLE_H1} color={badgeMiddle}>
          {"Нет данных"}
        </Text>
      )}
    </>
  );
};
