import React from "react";
import { routes } from "./routes";
import { renderRoutes } from "react-router-config";
import { DynamicTitle } from "./features/dynamicTitle/DynamicTitle";

export const App = () => {
  return (
    <>
      <DynamicTitle />
      {renderRoutes(routes as any)}
    </>
  );
};
