// @ts-ignore
import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../../../components/text/Text";

const Wrapper = styled.div`
  width: max-content;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.greenDark};
  border-radius: 16px;
`;

export const Badge = ({ product_id }: { product_id: string }) => {
  const theme: any = useTheme();
  const { greenDark } = theme.colors;

  const value =
    product_id === "discounts"
      ? "Акции"
      : product_id === "plumbing"
      ? "Сантехника"
      : product_id === "tiles"
      ? "Плитка"
      : product_id === "furniture"
      ? "Мебель"
      : product_id === "kitchen"
      ? "Кухни"
      : product_id === "light"
      ? "Свет"
      : "";

  return (
    <Wrapper theme={theme} className={"badge"}>
      <Text type={TextType.TEXT_14_REGULAR} color={greenDark}>
        {value.toUpperCase()}
      </Text>
    </Wrapper>
  );
};
