import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../../api/products";
import { CommonButton } from "../../../../../components/commonButton/CommonButton";
import { LoaderBlock } from "../../../../../components/loaderBlock/LoaderBlock";
import { Text, TextType } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { DataList } from "./components/dataList/DataList";
import { DiscountsPlaceholder } from "./components/discountsPlaceholder/DiscountsPlaceholder";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 240px;
  position: relative;
  ${media.tablet`
    margin-top: 120px;
  `}
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 56px;
  ${media.tabletS`
    & > button > div {
      right: 27%;
    }
  `}
  ${media.phone`
    & > button > div {
      right: 25%;
    }
  `}
  ${media.phoneS`
    & > button > div {
      right: 23%;
    }
  `}
  ${media.phoneXS`
    & > button > div {
      right: 19%;
    }
  `}
`;
const Title = styled(Text)`
  ${media.tablet`
    font-size: 40px;
    line-height: 40px;
  `}
`;

export const MainDiscounts = ({
  data,
  discountItemOnClick,
  loadMoreOnClick,
  addItemToChosenGoods,
  removeItemFromChosenGoods,
  showAllDiscounts,
  goToCatalog,
  isLoadMoreButtonDisplayed,
  isLoadingDataInProcess,
}: {
  data: GoodType[];
  discountItemOnClick: (id: string) => void;
  loadMoreOnClick: () => void;
  addItemToChosenGoods: (item: GoodType) => void;
  removeItemFromChosenGoods: (id: string) => void;
  showAllDiscounts: () => void;
  goToCatalog: () => void;
  isLoadMoreButtonDisplayed: boolean;
  isLoadingDataInProcess?: boolean;
}) => {
  const theme: any = useTheme();
  const { white1 } = theme.colors;

  return (
    <Wrapper>
      <Title type={TextType.TITLE_H1} color={white1} margin={"0 0 32px 0"}>
        {"Акции"}
      </Title>
      {data && data.length > 0 ? (
        <DataList
          data={data}
          dataItemOnClick={discountItemOnClick}
          addItemToChosenGoods={addItemToChosenGoods}
          removeItemFromChosenGoods={removeItemFromChosenGoods}
          showAllDiscounts={showAllDiscounts}
        />
      ) : (
        <DiscountsPlaceholder showButtonOnClick={goToCatalog} />
      )}

      {isLoadMoreButtonDisplayed && (
        <ButtonWrapper>
          <CommonButton
            value={"ЗАГРУЗИТЬ ЕЩЕ"}
            onClick={loadMoreOnClick}
            width={"416px"}
            backgroundColor={"transparent"}
            color={white1}
            borderColor={white1}
            isLoadingInProcess={isLoadingDataInProcess}
          />
        </ButtonWrapper>
      )}
      {isLoadingDataInProcess && <LoaderBlock />}
    </Wrapper>
  );
};
