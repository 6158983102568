import axios from "axios";
import { useEffect, useState } from "react";
import placeholderImage from "../assets/imagePlaceholder.png";
import { API_URL } from "../consts";

export const useGetBrands = (
  limit: number,
  setIsLoadingDataInProcess: (value: boolean) => void
) => {
  const [localData, setLocalData] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/brands?limit=${limit}`)
      .then((res) => {
        const { data } = res;
        const preparedData = data.payload.map((item: any) => ({
          ...item,
          image: placeholderImage,
        }));
        console.log("data.payload: ", data.payload);
        setIsLoadingDataInProcess(false);
        setLocalData(preparedData);
      })
      .catch((error) => {
        setIsLoadingDataInProcess(false);
        console.log("error: ", error);
      });
  }, [limit]);

  return localData;
};
