import styled from "styled-components";
import { General } from "../general/General";
import { View } from "../../routes/routeInterfaces";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { PageWrapper } from "../../containers/general/contacts/Contacts";
import { CenteredWrapper } from "../../containers/general/main/Main";
import {
  selectSearchbarValue,
  setSearchbarValue,
} from "../../app/state/goodsSlice";
import { useDispatch, useSelector } from "react-redux";
import { media } from "../../styles/media";
import { useState } from "react";
import { TopPart } from "./components/topPart/TopPart";
import { useFilters } from "../../features/filters/useFilters";
import { Filters } from "../../features/filters/components/filters/Filters";
import { useFilterContext } from "../../features/filters/filter.context";
import { LoaderBlock } from "../../components/loaderBlock/LoaderBlock";
import { useSearch } from "../../features/search/search";
import { useCurrentView } from "../../routes/currentView";

const Wrapper = styled(CenteredWrapper)`
  max-width: 1376px;
  box-sizing: border-box;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  padding: 24px 0 0 0;
  ${media.tablet`
    border: none;
  `}
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const FiltersWrapper = styled.div`
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 0 32px 0;
  border-right: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  ${media.tablet`
    display: none;
  `}
`;
const ContentWrapper = styled.div`
  width: calc(100% - 300px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 32px 32px 240px 32px;
  ${media.tablet`
    width: 100%;
    padding: 24px 24px 120px 24px;
  `}
`;

export const Goods = ({
  currentPath,
  pageIndicator,
  fullDataListLength,
  isLoadingDataInProcess,
  children,
}: {
  currentPath: string;
  pageIndicator: string;
  fullDataListLength: number;
  isLoadingDataInProcess: boolean;

  children: any;
}) => {
  const history = useCustomHistory();
  const dispatch = useDispatch();
  const { isGoods } = useCurrentView();
  const searchbarValue = useSelector(selectSearchbarValue);
  const [isOpenMobileFiltersModal, setIsOpenMobileFiltersModale] =
    useState(false);

  useFilters(isGoods ? "products" : "collections");
  const [, , { reset }] = useFilterContext();

  const pageOptions = isGoods
    ? [
        { name: "АКЦИИ", id: "discounts", isDisabled: true },
        { name: "ПЛИТКА", id: "tiles" },
        { name: "САНТЕХНИКА", id: "plumbing", isDisabled: true },
        { name: "МЕБЕЛЬ", id: "furniture", isDisabled: true },
        { name: "КУХНИ", id: "kitchen", isDisabled: true },
        { name: "СВЕТ", id: "light", isDisabled: true },
      ]
    : [
        { name: "ПЛИТКА", id: "tiles" },
        { name: "САНТЕХНИКА", id: "plumbing", isDisabled: true },
        { name: "МЕБЕЛЬ", id: "furniture", isDisabled: true },
        { name: "КУХНИ", id: "kitchen", isDisabled: true },
        { name: "СВЕТ", id: "light", isDisabled: true },
      ];

  const goodsCollectionsSwitcherOnClick = (isGoods: boolean) => {
    if (isGoods) {
      history.push(View.PRODUCTS, {
        pathParams: { section: "tiles", limit: 12 },
      });
    } else {
      history.push(View.COLLECTIONS, {
        pathParams: { section: "tiles", limit: 12 },
      });
    }
  };
  const pageOptionOnClick = (id: string) => {
    history.push(isGoods ? View.PRODUCTS : View.COLLECTIONS, {
      pathParams: { section: id, limit: 12 },
    });
  };

  const openFiltersModal = () => {
    setIsOpenMobileFiltersModale(true);
  };
  const closeFiltersModal = () => {
    setIsOpenMobileFiltersModale(false);
  };
  const readyButtonOnClickFiltersModal = () => {
    setIsOpenMobileFiltersModale(false);
  };
  const resetFilterButtonOnClickFiltersModal = () => {
    reset(isGoods ? "products" : "collections");
    setIsOpenMobileFiltersModale(false);
  };

  const [search, setSearch] = useSearch();

  return (
    <General currentPath={currentPath}>
      <PageWrapper>
        <Wrapper>
          <TopPart
            pageIndicator={pageIndicator}
            pageOptionsArr={pageOptions}
            currentSection={pageIndicator}
            fullDataListLength={fullDataListLength}
            searchbarValue={search}
            isOpenMobileFiltersModal={isOpenMobileFiltersModal}
            goodsCollectionsSwitcherOnClick={goodsCollectionsSwitcherOnClick}
            pageOptionOnClick={pageOptionOnClick}
            searchbarOnChange={setSearch}
            openFiltersModal={openFiltersModal}
            closeFiltersModal={closeFiltersModal}
            readyButtonOnClickFiltersModal={readyButtonOnClickFiltersModal}
            resetFilterButtonOnClickFiltersModal={
              resetFilterButtonOnClickFiltersModal
            }
          />
          <Container>
            <FiltersWrapper>
              <Filters />
            </FiltersWrapper>
            <ContentWrapper>
              {isLoadingDataInProcess && <LoaderBlock />}
              {children}
            </ContentWrapper>
          </Container>
        </Wrapper>
      </PageWrapper>
    </General>
  );
};
