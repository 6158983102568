// @ts-ignore
import styled, { useTheme } from "styled-components";
import { media } from "../../../../styles/media";

const Wrapper = styled.div`
  display: none;
  ${media.desktop`
    display: flex;
    align-items: center;
  `}
`;
const BurgerButton = styled.div<any>`
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 1000000;
  display: none;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  & div {
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: ${(props: any) =>
      props.modalIsOpen
        ? props.theme.colors.black
        : props.withLargeLogo
        ? props.theme.colors.white
        : props.color};
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${(props: any) =>
        props.modalIsOpen
          ? props.theme.colors.black
          : props.withLargeLogo
          ? props.theme.colors.white
          : props.color};
      transition: all 0.3s ease-in-out;
      border-radius: 10px;
    }
    &:before {
      transform: translateY(6px);
      border-radius: 10px;
    }
    &:after {
      transform: translateY(-6px);
      border-radius: 10px;
    }
  }
  &.open div {
    transform: translateX(-100%);
    background-color: transparent;
    border-radius: 10px;
    &:before {
      transform: translateX(100%) rotate(-45deg);
      border-radius: 10px;
      background-color: ${(props: any) =>
        props.withLargeLogo
          ? props.theme.colors.white
          : props.theme.colors.black};
    }
    &:after {
      transform: translateX(100%) rotate(45deg);
      border-radius: 10px;
      background-color: ${(props: any) =>
        props.withLargeLogo
          ? props.theme.colors.white
          : props.theme.colors.black};
    }
  }
  ${media.desktop`
    display: flex;
  `}
`;

export const Burger = ({
  onClick,
  isOpenMobileMenu,
  withLargeLogo,
}: {
  onClick: () => void;
  isOpenMobileMenu: boolean;
  withLargeLogo?: boolean;
}) => {
  const theme: any = useTheme();

  const { black } = theme.colors;

  return (
    <Wrapper onClick={onClick}>
      <BurgerButton
        className={isOpenMobileMenu ? "open" : ""}
        color={black}
        modalIsOpen={isOpenMobileMenu}
        black={black}
        withLargeLogo={withLargeLogo}
      >
        <div />
      </BurgerButton>
    </Wrapper>
  );
};
