import { useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { CommonButton } from "../../../../../../components/commonButton/CommonButton";
import { Burger } from "../../../../../../components/header/components/burger/Burger";
import { Modal } from "../../../../../../components/modal/Modal";
import { Text, TextType } from "../../../../../../components/text/Text";
import { Filters } from "../../../../../../features/filters/components/filters/Filters";
import { media } from "../../../../../../styles/media";

const Container = styled(Modal)`
  justify-content: flex-start;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props: any) => props.theme.colors.white1};
`;
const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 25px 68px 25px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
`;
const Title = styled(Text)`
  font-weight: 500;
  line-height: 24px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 40px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;

  ${media.tabletS`
    grid-template-columns: 1fr;
  `}

  & > button {
    width: 100%;
  }
`;
const ScrolledWrapper = styled.div`
  width: 100%;
  max-height: calc(100% - 242px);
  display: flex;
  overflow-y: auto;
  ${media.tabletS`
    max-height: calc(100% - 312px);
  `}
`;
const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

//@TODO handle isGoods case
export const MobileFiltersModal = ({
  isOpenModal,
  closeModal,
  readyButtonOnClick,
  resetFilterButtonOnClick,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  readyButtonOnClick: () => void;
  resetFilterButtonOnClick: () => void;
}) => {
  const theme: any = useTheme();
  const { black, white } = theme.colors;
  const currentPath = useLocation().pathname;

  return (
    <Container isOpenModal={isOpenModal}>
      <Wrapper>
        <LogoWrapper>
          <Burger isOpenMobileMenu={isOpenModal} onClick={closeModal} />
          <Title type={TextType.TITLE_H2} margin={"0 0 0 8px"} color={black}>
            {"Фильтры"}
          </Title>
        </LogoWrapper>
        <ScrolledWrapper>
          <FiltersWrapper>
            <Filters />
          </FiltersWrapper>
        </ScrolledWrapper>
        <ButtonsWrapper>
          <CommonButton
            value={"ГОТОВО"}
            onClick={readyButtonOnClick}
            backgroundColor={black}
            borderColor={black}
            color={white}
            backgroundColorHover={"transparent"}
            borderColorHover={black}
            colorHover={black}
          />
          <CommonButton
            value={"СБРОСИТЬ ФИЛЬТРЫ"}
            onClick={resetFilterButtonOnClick}
            backgroundColor={"transparent"}
            borderColor={black}
            color={black}
            backgroundColorHover={black}
            borderColorHover={black}
            colorHover={white}
          />
        </ButtonsWrapper>
      </Wrapper>
    </Container>
  );
};
