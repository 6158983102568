import { stringifyUrl } from "query-string";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetFiltersList } from "../../api/filters";
import { useFilterContext } from "./filter.context";

export const useFilters = (mode: "products" | "collections" = "products") => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [, , { initFilters, getActiveFilters }] = useFilterContext();

  useGetFiltersList(mode, (data) => initFilters(mode, data));

  const filters = getActiveFilters(mode);

  useEffect(() => {
    if (filters && filters.length > 0) {
      history.replace(
        stringifyUrl({
          url: pathname,
          query: filters.reduce(
            (result, filter) => ({
              ...result,
              [filter.filterKey]: filter.options.reduce((selected, option) => {
                const selectedCandidates = option.isSelected ? [option.id] : [];
                return [...selected, ...selectedCandidates];
              }, [] as string[]),
            }),
            {}
          ),
        })
      );
    }
  }, [filters]);

  return {};
};
