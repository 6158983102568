import { TextType } from "./Text";

export const getParams = (type: TextType, theme: any, color?: string) => {
  const params = {
    [TextType.TITLE_H1]: {
      fontWeight: "400",
      fontSize: "64px",
      lineHeight: "80px",
      color: theme.colors.gray1,
    },
    [TextType.TITLE_H2]: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "32px",
      color: theme.colors.gray1,
    },
    [TextType.TEXT_16_BOLD]: {
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.colors.gray1,
    },
    [TextType.TEXT_16_REGULAR]: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.colors.gray1,
    },
    [TextType.TEXT_14_REGULAR]: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
      color: theme.colors.gray1,
    },
    [TextType.TEXT_12_REGULAR]: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "24px",
      color: theme.colors.gray3,
    },
  };
  const values = params[type] || {};
  const result = color ? { ...values, color } : values;

  return result;
};
