// @ts-ignore
import styled from "styled-components";
import { GeneralView } from "../../../../enums/View";
import { media } from "../../../../styles/media";
import { MenuItem } from "../menuItem/MenuItem";

export interface MenuArrType {
  id: string;
  name: string;
  sections?: {
    id: string;
    name: string;
  }[];
  to: GeneralView;
}

const Wrapper = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  ${media.desktop`
    display: none;
  `}
`;

export const Menu = ({
  menuArr,
  currentPath,
}: {
  menuArr: MenuArrType[];
  currentPath: string;
}) => {
  return (
    <Wrapper>
      {menuArr.map((item: MenuArrType, index: number) => (
        <MenuItem
          key={index}
          data={item}
          className={index === menuArr.length - 1 ? "last" : ""}
          currentPath={currentPath}
        />
      ))}
    </Wrapper>
  );
};
