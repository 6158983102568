import { Text, TextType } from "../../../components/text/Text";
import styled, { useTheme } from "styled-components";
import { General } from "../../../layouts/general/General";
import { media } from "../../../styles/media";
import { CenteredWrapper } from "../../../components/header/Header";
import { useScrollReset } from "../../../features/useScrollReset/useScrollReset";

interface ContactType {
  name: string;
  values: string[];
}

export const PageWrapper = styled.div<any>`
  width: 100%;
  min-height: calc(100vh - 386px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  padding: ${(props: any) =>
    props.withLargeLogo ? "284px 32px 0 32px" : "88px 32px 0 32px"};
  ${media.desktop`
    padding: 80px 0 0 0;
  `}
`;
export const Wrapper = styled(CenteredWrapper)<any>`
  max-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${(props: any) =>
    props.borderColor ? props.borderColor : props.theme.colors.gray4};
  ${media.desktop`
    border: none;
  `}
`;
const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 64px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.gray4};
  ${media.tablet`
    border: none;
    padding: 25px;
  `}
  ${media.tabletS`
    & > span.title {
        display: none;
    }
  `}
`;
const DownPartWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 654px);
  display: grid;
  grid-template-columns: 1fr 2fr;
  ${media.tablet`
    height: max-content;
    grid-template-columns: 1fr;
    margin-bottom: 120px;
  `}
`;
const Container = styled.div`
  display: flex;
  ${media.tablet`
    &.empty {
        display: none;
    }
  `}
  ${media.tabletS`
    &.schedule {
        flex-direction: column;
        & > span.last {
        margin: 5px 0 0 0;
    } 
    }
  `}
`;
const ContactsList = styled.ul`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-left: 1px solid;
  border-color: ${(props: any) => props.theme.colors.gray4};
  margin: 0;
  padding: 0 0 160px 0;
  ${media.tablet`
    grid-template-columns: 1fr;
    padding: 0;
    border: none;
  `}
`;
const ContactItem = styled.li`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 32px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.gray4};
  ${media.tablet`
    padding: 20px 25px;

  `}
`;

export const Contacts = ({ match }: { match: any }) => {
  const theme: any = useTheme();
  const currentPath = match.path;

  const contactsArr = [
    { name: "E-mail", values: ["positanogrupp@gmail.com"] },
    { name: "Телефон", values: ["+375 (44) 799-70-70"] },
    { name: "Aдрес", values: ["Брест, ул. Гоголя 83"] },
    {
      name: "Время работы:",
      values: ["Пн-Пт: 10:00–18:00", "Сб: 10:00–16:00"],
    },
  ];

  useScrollReset();

  return (
    <General currentPath={currentPath}>
      <PageWrapper theme={theme}>
        <Wrapper>
          <TitleWrapper theme={theme}>
            <Text
              className={"title"}
              type={TextType.TITLE_H1}
              margin={"0 0 16px 0"}
            >
              Как с нами связаться?
            </Text>
            <Text type={TextType.TEXT_16_REGULAR} className={"description"}>
              Вы можете задать любой интересующий вас вопрос по товару!
            </Text>
          </TitleWrapper>
          <DownPartWrapper>
            <Container className={"empty"} />
            <ContactsList>
              {contactsArr.map((item: ContactType, index: number) => {
                const { name, values } = item;
                return (
                  <ContactItem key={index}>
                    <Text type={TextType.TEXT_14_REGULAR}>
                      {name.toUpperCase()}
                    </Text>
                    <Container className={"schedule"}>
                      {values.map((value: string, index: number) => (
                        <Text
                          className={
                            values.length > 1 && index === values.length - 1
                              ? "last"
                              : ""
                          }
                          key={index}
                          type={TextType.TEXT_14_REGULAR}
                          margin={index !== 0 ? "0 0 0 32px" : ""}
                        >
                          {value}
                        </Text>
                      ))}
                    </Container>
                  </ContactItem>
                );
              })}
            </ContactsList>
          </DownPartWrapper>
        </Wrapper>
      </PageWrapper>
    </General>
  );
};
