import { GeneralView } from "../enums/View";
import { CombinedRoutes, View } from "./routeInterfaces";

export const MAPPING = Object.freeze({
  [View.MAIN]: { path: "/main" },
  [View.ABOUT_US]: { path: "/about_us" },
  [View.CONTACTS]: { path: "/contacts" },
  [View.COOPERATION]: { path: "/cooperation" },
  [View.BRANDS]: { path: "/brands" },
  [View.PRODUCTS]: { path: "/products/:section/:limit" },
  [View.PRODUCTS_DETAILS]: { path: "/products/:id" },
  [View.COLLECTIONS]: { path: "/collections/:section/:limit" },
  [View.COLLECTIONS_DETAILS]: { path: "/collections/:id" },
  [View.REDIRECT]: { path: "/" },
}) as CombinedRoutes;

export const getPath = (view: typeof View[keyof typeof View]) =>
  MAPPING[view] ? MAPPING[view].path : "";

export const PATHS = Object.keys(View)
  .map((key) => ({
    [key]: getPath((View as any)[key]),
  }))
  .reduce(
    (obj, item) => ({
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }),
    {}
  ) as CombinedRoutes;

export const VIEW_PATH_PAIRS = Object.keys(MAPPING).map((key) => ({
  view: key,
  path: getPath(key as GeneralView),
}));

export const injectPathParams = (route: string, params: any) => {
  let newRoute = route;
  Object.keys(params).forEach(function (key, index) {
    const regex = new RegExp(`:${key}`, "g");
    newRoute = newRoute.replace(regex, params[key]);
  });
  return newRoute as any;
};
