import styled, { useTheme } from "styled-components";
import { Logo, LogoType } from "../../../logo/Logo";
import { Modal } from "../../../modal/Modal";
import { Burger } from "../burger/Burger";
import { MenuArrType } from "../menu/Menu";
import { Text, TextType } from "../../../../components/text/Text";
import { useCustomHistory } from "../../../../utils/react-router-dom-abstraction";
import { GeneralView } from "../../../../enums/View";
import { CommonButton } from "../../../commonButton/CommonButton";

const Container = styled(Modal)`
  justify-content: flex-start;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props: any) => props.theme.colors.white1};
`;
const LogoWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 25px;
  & > div > svg {
    margin-left: 60px;
  }
`;
const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const MenuItem = styled.div`
  width: 100%;
  padding: 0 25px 24px 25px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  margin-bottom: 24px;
  &.last {
    margin-bottom: 32px;
  }
  & > span.active {
    border-bottom: 1px solid;
    border-color: ${(props: any) => props.theme.colors.black};
    pointer-events: none;
  }
`;
const PhoneNumber = styled(Text)`
  width: max-content;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  margin: 0 0 64px 25px;
`;
const Button = styled(CommonButton)`
  margin-left: 25px;
`;

export const MobileMenu = ({
  menuArr,
  isOpenMobileMenu,
  currentPath,
  burgerOnClick,
}: {
  menuArr: MenuArrType[];
  isOpenMobileMenu: boolean;
  currentPath: string;
  burgerOnClick: () => void;
}) => {
  const theme: any = useTheme();
  const { gray1, white, black } = theme.colors;
  const history = useCustomHistory();

  const menuItemOnClick = (to: GeneralView, id: string) => {
    id === "products"
      ? history.push(to, { pathParams: { section: "tiles", limit: 12 } })
      : history.push(to);
    burgerOnClick();
  };

  return (
    <Container isOpenModal={isOpenMobileMenu}>
      <Wrapper>
        <LogoWrapper>
          <Burger isOpenMobileMenu={isOpenMobileMenu} onClick={burgerOnClick} />
          <Logo type={LogoType.SMALL} color={gray1} />
        </LogoWrapper>
        <MenuWrapper>
          {menuArr.map((item: MenuArrType, index: number) => {
            const { name, to, id } = item;
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  menuItemOnClick(to, id);
                }}
                className={index === menuArr.length - 1 ? "last" : ""}
                theme={theme}
              >
                <Text
                  type={TextType.TEXT_14_REGULAR}
                  className={currentPath.includes(id) ? "active" : ""}
                  color={black}
                >
                  {name.toUpperCase()}
                </Text>
              </MenuItem>
            );
          })}
          <PhoneNumber type={TextType.TEXT_14_REGULAR} color={black}>
            {"+375 (44) 799-70-70"}
          </PhoneNumber>
          <Button
            onClick={() => {
              console.log("Shop");
            }}
            value={"Магазин"}
            width={"118px"}
            height={"32px"}
            backgroundColor={"transparent"}
            borderColor={black}
            color={black}
            colorHover={white}
            borderColorHover={white}
            backgroundColorHover={black}
          />
        </MenuWrapper>
      </Wrapper>
    </Container>
  );
};
