import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../components/text/Text";
import { ReactComponent as ArrowIcon } from "../../../../../assets/arrow_right.svg";
import { media } from "../../../../../styles/media";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > span {
    word-break: break-word;
  }

  ${media.desktop`
    padding: 0 24px;
  `}
`;
const ButtonsWrapper = styled.div`
  display: flex;
`;
const Button = styled.div`
  width: 50px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  border-radius: 16px;
  transition: all 0.3s linear;
  cursor: pointer;

  & > svg > path {
    transition: all 0.3s linear;
  }

  &:hover {
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.badgeMiddle};
      transition: all 0.3s linear;
    }
  }

  &.left {
    margin-right: 12px;
    & > svg {
      transform: rotate(180deg);
    }
  }
  &.disabled {
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
    pointer-events: none;
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.badgeMiddle};
      transition: all 0.3s linear;
    }
  }
`;

export const Controllers = ({
  currentValue,
  maxValue,
  leftOnClick,
  rigtOnClick,
  ...props
}: {
  currentValue: number;
  maxValue: number;
  leftOnClick: () => void;
  rigtOnClick: () => void;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle } = theme.colors;

  return (
    <Wrapper {...props}>
      <Text
        type={TextType.TEXT_12_REGULAR}
        color={badgeMiddle}
      >{`${currentValue}/${maxValue}`}</Text>
      <ButtonsWrapper>
        <Button
          onClick={leftOnClick}
          className={`left ${currentValue === 1 ? "disabled" : ""}`}
        >
          <ArrowIcon />
        </Button>
        <Button
          onClick={rigtOnClick}
          className={currentValue === maxValue ? "disabled" : ""}
        >
          <ArrowIcon />
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};
