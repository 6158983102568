import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../../../components/text/Text";

const Wrapper = styled.div`
  width: max-content;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  border-radius: 16px;
  margin-right: 8px;
  &.last {
    margin: 0;
  }
`;

export const SubCategory = ({
  data,
  ...props
}: {
  data: { name: string; id: string };
  className?: string;
}) => {
  const theme: any = useTheme();
  const { badgeDark } = theme.colors;
  const { name } = data;

  return (
    <Wrapper {...props}>
      <Text type={TextType.TEXT_14_REGULAR} color={badgeDark}>
        {name.toUpperCase()}
      </Text>
    </Wrapper>
  );
};
