import { ReactNode } from "react";
// @ts-ignore
import styled, { useTheme } from "styled-components";

import { getParams } from "./textParams";

export enum TextType {
  TITLE_H1 = "TITLE_H1",
  TITLE_H2 = "TITLE_H2",
  TEXT_16_BOLD = "TEXT_16_BOLD",
  TEXT_16_REGULAR = "TEXT_16_REGULAR",
  TEXT_14_REGULAR = "TEXT_14_REGULAR",
  TEXT_12_REGULAR = "TEXT_12_REGULAR",
}

const BaseSpan = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: ${(props: any) => props.fontWeight};
  font-size: ${(props: any) => props.fontSize};
  line-height: ${(props: any) => props.lineHeight};
  color: ${(props: any) => props.color};
  margin: ${(props: any) => (props.margin ? props.margin : "0")};
  transition: ${(props: any) =>
    props.transition ? props.transition : "all 0.3s ease"};
  cursor: ${(props: any) => (props.onClick ? "pointer" : "auto")};
  &:hover {
    color: ${(props: any) =>
      props.hoverColor ? props.hoverColor : props.color};
  }
`;

export const Text = ({
  children,
  type,
  color,
  margin,
  ...props
}: {
  type: TextType;
  children: ReactNode;
  color?: string;
  hoverColor?: string;
  weight?: string;
  margin?: string;
  lineHeight?: string;
  size?: string;
  onClick?: (e?: any) => void;
  className?: string;
}) => {
  const theme = useTheme();
  const params = getParams(type, theme, color);
  const values = { ...params, margin };

  return (
    <BaseSpan {...values} {...props}>
      {children}
    </BaseSpan>
  );
};
