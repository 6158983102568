import styled, { useTheme } from "styled-components";
import { CommonButton } from "../../../../../../../components/commonButton/CommonButton";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { View } from "../../../../../../../routes/routeInterfaces";
import { media } from "../../../../../../../styles/media";
import { useCustomHistory } from "../../../../../../../utils/react-router-dom-abstraction";

const Wrapper = styled.div<any>`
  width: 376px;
  box-sizing: border-box;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${(props: any) => props.theme.colors.greenDark};
  ${media.tabletS`
    background-color: transparent;
    padding: 0;

    & > button {
        display: none;
    }
  `}
`;
const DescriptionWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;

  & > span {
    width: calc(100% - 18px);
  }
  ${media.tabletS`
    justify-content: flex-start;
    margin: 0;
  `}
`;
const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: any) => props.theme.colors.white1};
  margin-top: 7px;
`;

export const EscortPopup = () => {
  const theme: any = useTheme();
  const { white1 } = theme.colors;
  const history = useCustomHistory();

  const showCatalogOnClick = () => {
    history.push(View.PRODUCTS, {
      pathParams: { section: "tiles", limit: 12 },
    });
  };

  return (
    <Wrapper>
      <DescriptionWrapper>
        <Dot />
        <Text
          type={TextType.TEXT_16_REGULAR}
          color={white1}
          margin={"0 0 0 8px"}
        >
          Сопровождаем работы по дизайну. Поставляем материалы.
        </Text>
      </DescriptionWrapper>
      <CommonButton
        width={"100%"}
        height={"40px"}
        value={"CМОТРЕТЬ КАТАЛОГ"}
        onClick={showCatalogOnClick}
        backgroundColor={"transparent"}
        backgroundColorHover={white1}
        borderColor={white1}
        color={white1}
      ></CommonButton>
    </Wrapper>
  );
};
