// @ts-ignore
import styled, { useTheme } from "styled-components";
import { ReactComponent as LargeLogoIcon } from "../../assets/logo_large.svg";
import { ReactComponent as SmallWithTextLogoIcon } from "../../assets/logo_with_text.svg";
import { ReactComponent as SmallLogoIcon } from "../../assets/logo_small.svg";

export enum LogoType {
  LARGE = "LARGE",
  SMALL_WITH_TEXT = "SMALL_WITH_TEXT",
  SMALL = "SMALL",
}

const Wrapper = styled.div`
  display: flex;
  cursor: ${(props: any) => (props.onClick ? "pointer" : "auto")};
  & > svg > path {
    fill: ${(props: any) => props.color};
  }
  & > svg > path:nth-child(7) {
    fill: transparent;
  }
  & > svg > path:nth-child(8) {
    fill: transparent;
  }
`;

export const Logo = ({
  type,
  onClick,
  color,
  ...props
}: {
  type: LogoType;
  onClick?: () => void;
  color?: string;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { white } = theme.colors;

  return (
    <Wrapper onClick={onClick} color={color ? color : white} {...props}>
      {type === LogoType.LARGE ? (
        <LargeLogoIcon />
      ) : type === LogoType.SMALL_WITH_TEXT ? (
        <SmallWithTextLogoIcon />
      ) : type === LogoType.SMALL ? (
        <SmallLogoIcon />
      ) : null}
    </Wrapper>
  );
};
