import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../api/products";
import { ChosenItem } from "../../../choosenGoods/components/chosenItem/ChosenItem";
import { Modal } from "../../../modal/Modal";
import { Text, TextType } from "../../../text/Text";
import { Burger } from "../burger/Burger";

const Container = styled(Modal)`
  justify-content: flex-start;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props: any) => props.theme.colors.white1};
`;
const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 25px 68px 25px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
`;
const Title = styled(Text)`
  font-weight: 500;
  line-height: 24px;
`;
const GoodsWrapper = styled.div`
  width: 100%;
  height: calc(100% - 93px);
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  &.empty {
    align-items: center;
    justify-content: center;
  }
`;

export const ChosenGoodsModal = ({
  chosenGoods,
  isOpenModal,
  closeModal,
  deleteChosenGood,
  openChosenGood,
}: {
  chosenGoods: GoodType[];
  isOpenModal: boolean;
  closeModal: () => void;
  deleteChosenGood: (id: string) => void;
  openChosenGood: (id: string) => void;
}) => {
  const theme: any = useTheme();
  const { black } = theme.colors;
  const isEmpty = chosenGoods.length === 0;

  return (
    <Container isOpenModal={isOpenModal}>
      <Wrapper>
        <LogoWrapper>
          <Burger isOpenMobileMenu={isOpenModal} onClick={closeModal} />
          <Title type={TextType.TITLE_H2} margin={"0 0 0 8px"} color={black}>
            {"Избранное"}
          </Title>
        </LogoWrapper>
        <GoodsWrapper className={isEmpty ? "empty" : ""}>
          {isEmpty ? (
            <Text type={TextType.TEXT_16_BOLD} color={black}>
              {"Нет избранных товаров..."}
            </Text>
          ) : (
            chosenGoods.map((good: GoodType, index: number) => {
              return (
                <ChosenItem
                  key={index}
                  className={index === chosenGoods.length - 1 ? "last" : ""}
                  data={good}
                  deleteChosenGood={deleteChosenGood}
                  openChosenGood={openChosenGood}
                />
              );
            })
          )}
        </GoodsWrapper>
      </Wrapper>
    </Container>
  );
};
