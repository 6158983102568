import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../components/text/Text";
import { CollectionItem } from "../collectionsItem/CollectionItem";

const Wrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin: 0;
  padding: 0;
`;

export const CollectionsList = ({
  data,
  listItemOnClick,
}: {
  data: any[];
  listItemOnClick: (id: string) => void;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle } = theme.colors;

  return (
    <>
      {data.length > 0 ? (
        <Wrapper>
          {data.map((item: any, index: number) => {
            return (
              <CollectionItem
                data={item}
                key={index}
                listItemOnClick={listItemOnClick}
              />
            );
          })}
        </Wrapper>
      ) : (
        <Text type={TextType.TITLE_H1} color={badgeMiddle}>
          {"Нет данных"}
        </Text>
      )}
    </>
  );
};
