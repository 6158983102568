// @ts-ignore
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ChoosenGoods } from "../../components/choosenGoods/ChoosenGoods";
import { Footer } from "../../components/footer/Footer";
import { MenuArrType } from "../../components/header/components/menu/Menu";
import { Header } from "../../components/header/Header";
import { View } from "../../routes";
import {
  removeChosenGood,
  selectChosenGoods,
} from "../../app/state/chosenGoodsSlice";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const General = ({
  children,
  currentPath,
  backgroundColor,
  borderColor,
}: {
  children: ReactNode;
  currentPath: string;
  backgroundColor?: string;
  borderColor?: string;
}) => {
  const [isOpenChosenGoods, setIsChosenGoods] = useState(false);
  const chosenGoodsLocal = useSelector(selectChosenGoods);

  const history = useCustomHistory();
  const dispatch = useDispatch();
  const headerArr: MenuArrType[] = [
    {
      id: "products",
      name: "Каталог",
      sections: [
        /*{ id: "discounts", name: "Акции" },
        { id: "plumbing", name: "Сантехника" },*/
        { id: "tiles", name: "Плитка\xa0\xa0\xa0\xa0\xa0\xa0" },
        /* { id: "furniture", name: "Мебель" },
        { id: "kitchen", name: "Кухни" },
        { id: "light", name: "Свет" },*/
      ],
      to: View.PRODUCTS,
    },
    { id: "brands", name: "Бренды", to: View.BRANDS },
    { id: "contacts", name: "Контакты", to: View.CONTACTS },
  ];
  const footerArr: MenuArrType[] = [
    { id: "main", name: "Главная", to: View.MAIN },
    {
      id: "products",
      name: "Каталог",
      to: View.PRODUCTS,
    },
    { id: "brands", name: "Бренды", to: View.BRANDS },
    { id: "cooperation", name: "Сотрудничество", to: View.COOPERATION },
  ];

  const openChosenGoods = () => {
    setIsChosenGoods(true);
  };
  const closeChosenGoods = () => {
    setIsChosenGoods(false);
  };
  const deleteChosenGood = (id: string) => {
    dispatch(removeChosenGood({ id }));
  };

  const openChosenGood = (id: string) => {
    history.push(View.PRODUCTS_DETAILS, { pathParams: { id } });
  };

  useEffect(() => {
    window.localStorage.setItem(
      "chosenGoods",
      JSON.stringify(chosenGoodsLocal)
    );
  }, [chosenGoodsLocal]);

  return (
    <Wrapper>
      <ChoosenGoods
        isOpenChosenGoods={isOpenChosenGoods}
        openChosenGoods={openChosenGoods}
        closeChosenGoods={closeChosenGoods}
        deleteChosenGood={deleteChosenGood}
        openChosenGood={openChosenGood}
      />
      <Header
        menuArr={headerArr}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        deleteChosenGood={deleteChosenGood}
      />
      {children}
      <Footer
        menuArr={footerArr}
        currentPath={currentPath}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      />
    </Wrapper>
  );
};
