import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  addChosenGood,
  removeChosenGood,
  selectChosenGoods,
} from "../../../app/state/chosenGoodsSlice";
import { Crumbs } from "../../../components/crumbs/Crumbs";
import { PageWrapper } from "../../general/contacts/Contacts";
import { CenteredWrapper } from "../../general/main/Main";
import { View } from "../../../routes/routeInterfaces";
import { media } from "../../../styles/media";
import { useCustomHistory } from "../../../utils/react-router-dom-abstraction";
import { General } from "../../../layouts/general/General";
import { Image } from "./components/image/Image";
import { Info } from "./components/info/Info";
import { SimilarProducts } from "./components/similarProducts/SimilarProducts";
import {
  GoodType,
  useGetProductData,
  useGetSimilarProducts,
} from "../../../api/products";
import {
  selectIsLoadingDataInProcess,
  setIsLoadingData,
} from "../../../app/state/goodsSlice";
import { LoaderBlock } from "../../../components/loaderBlock/LoaderBlock";
import { useScrollReset } from "../../../features/useScrollReset/useScrollReset";

const Container = styled(PageWrapper)`
  ${media.desktop`
    padding: 80px 0 0 0;
  `}
`;
const Wrapper = styled(CenteredWrapper)`
  max-width: 1376px;
  box-sizing: border-box;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  padding: 24px 0 0 0;
  ${media.desktop`
    border: none;
  `}
`;
const CrumbsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 0 32px;
  & > div {
    margin-bottom: 24px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 32px 32px 32px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  margin-bottom: 240px;
  position: relative;
  ${media.desktop`
    padding: 0 0 32px 0;
    margin-bottom: 120px;
  `}
`;
const DesktopImage = styled(Image)`
  ${media.desktop`
    display: none;
  `}
`;
const StyledCrumbs = styled(Crumbs)`
  ${media.desktop`
    display: none;
  `}
`;

export const ProductsDetails = ({ match }: { match: any }) => {
  const dispatch = useDispatch();
  const currentPath = match.path;
  const pathParams = match.params;
  const history = useCustomHistory();
  const { id } = pathParams;
  const [similarProductsLimit, setSimilarProductsLimit] = useState(3);
  const [
    isLoadingSimilarProductsInProgress,
    setIsLoadingSimilarProductsInProgress,
  ] = useState(false);

  const isLoadingDataInProcess = useSelector(selectIsLoadingDataInProcess);
  const setIsLoadingDataInProcess = (value: boolean) => {
    dispatch(setIsLoadingData({ value }));
  };

  const data = useGetProductData(id, setIsLoadingDataInProcess) as GoodType;
  const chosenGoods = useSelector(selectChosenGoods);
  const isChosen =
    chosenGoods.findIndex((item: GoodType) => item.id === id) !== -1;
  const similarProducts = useGetSimilarProducts(
    id,
    similarProductsLimit,
    setIsLoadingSimilarProductsInProgress
  );
  const isLoadMoreButtonDisplayed =
    similarProducts.length > 3 &&
    similarProducts.length - similarProductsLimit > 0;
  const {
    name,
    section,
    image,
    articleId,
    isAvailable,
    readableProperties,
    url,
  } = data;

  const addItemToChosenGoods = (item: GoodType) => {
    dispatch(addChosenGood({ value: item }));
  };
  const removeItemFromChosenGoods = (id: string) => {
    dispatch(removeChosenGood({ id }));
  };
  const goToShopOnClick = () => {
    console.log("GO TO SHOP");
  };
  const orderConsultationOnClick = () => {
    history.push(View.CONTACTS);
  };
  const loadMoreOnClick = () => {
    setIsLoadingSimilarProductsInProgress(true);
    setTimeout(() => {
      setSimilarProductsLimit(similarProductsLimit + 3);
      setIsLoadingSimilarProductsInProgress(false);
    }, 3000);
  };
  const similarItemOnClick = (id: string) => {
    history.push(View.PRODUCTS_DETAILS, { pathParams: { id } });
  };
  const crumbsOnClick = () => {
    history.push(View.PRODUCTS, { pathParams: { section, limit: 12 } });
  };

  useEffect(() => {
    if (!isLoadingDataInProcess) {
      setIsLoadingDataInProcess(true);
    }
    if (!isLoadingSimilarProductsInProgress) {
      setIsLoadingSimilarProductsInProgress(true);
    }
  }, []);

  useScrollReset([id]);

  return (
    <General currentPath={currentPath}>
      <Container>
        <Wrapper>
          <CrumbsWrapper>
            <StyledCrumbs
              chapter={"Каталог"}
              pageIndicator={section}
              name={name}
              pageIndicatorOnClick={crumbsOnClick}
            />
          </CrumbsWrapper>
          <ContentWrapper>
            <DesktopImage
              img={image}
              isChosen={isChosen}
              addItemToChosenGoods={() => {
                addItemToChosenGoods(data);
              }}
              removeItemFromChosenGoods={() => {
                removeItemFromChosenGoods(id);
              }}
            />
            <Info
              name={name}
              vendorCode={articleId}
              status={+isAvailable}
              infoArr={readableProperties}
              img={image}
              isChosen={isChosen}
              url={url}
              goToShopOnClick={goToShopOnClick}
              orderConsultationOnClick={orderConsultationOnClick}
              addItemToChosenGoods={() => {
                addItemToChosenGoods(data);
              }}
              removeItemFromChosenGoods={() => {
                removeItemFromChosenGoods(id);
              }}
            />
            {isLoadingDataInProcess && <LoaderBlock />}
          </ContentWrapper>
          <SimilarProducts
            list={similarProducts}
            isLoadingSimilarProductsInProgress={
              isLoadingSimilarProductsInProgress
            }
            isLoadMoreButtonDisplayed={isLoadMoreButtonDisplayed}
            loadMoreOnClick={loadMoreOnClick}
            similarItemOnClick={similarItemOnClick}
            addItemToChosenGoods={addItemToChosenGoods}
            removeItemFromChosenGoods={removeItemFromChosenGoods}
          />
        </Wrapper>
      </Container>
    </General>
  );
};
