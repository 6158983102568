import { useEffect, useState } from "react";

export const useScrollReset = (triggers: any[] = []) => {
  const [isConsecutive, setIsConsecutive] = useState(false);

  useEffect(() => {
    !isConsecutive && setIsConsecutive(true);

    window.scrollTo({
      top: 0,
      left: 0,
      // @NOTE eslint seems to have outdated definition for behavior, thats why instant needs 'any' override
      behavior: !isConsecutive ? ("instant" as any) : "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...triggers]);
};
