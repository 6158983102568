import { SyntheticEvent } from "react";
import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../api/products";

import { Text, TextType } from "../../../text/Text";
import { GoodsImage } from "./components/goodsImage/GoodsImage";

const Wrapper = styled.li`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 28px;
  display: flex;
  border-bottom: 1px solid;
  cursor: pointer;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  &.last {
    border-color: transparent;
  }
`;
const Container = styled.div`
  width: calc(100% - 138px);
  display: flex;
  flex-direction: column;
`;
const SubcategoriesWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
const Title = styled(Text)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  cursor: pointer;
`;

export const ChosenItem = ({
  data,
  deleteChosenGood,
  openChosenGood,
  ...props
}: {
  data: GoodType;
  deleteChosenGood: (id: string) => void;
  openChosenGood: (id: string) => void;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { badgeDark, black } = theme.colors;
  const { id, image, types, name, dimensions } = data;

  return (
    <Wrapper
      {...props}
      onClick={() => {
        openChosenGood(id);
      }}
    >
      <GoodsImage
        src={image}
        removeChosenGood={(e: SyntheticEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          deleteChosenGood(id);
        }}
      />
      <Container>
        <SubcategoriesWrapper>
          {types.map((item: { name: string; id: string }, index: number) => (
            <StyledText
              key={index}
              type={TextType.TEXT_14_REGULAR}
              color={badgeDark}
              margin={index !== types.length - 1 ? "0 16px 0 0" : "0"}
            >
              {item.name.toUpperCase()}
            </StyledText>
          ))}
        </SubcategoriesWrapper>
        <Title type={TextType.TEXT_16_BOLD} color={black} margin={"0 0 8px 0"}>
          {name}
        </Title>
        <StyledText
          type={TextType.TEXT_16_REGULAR}
          color={badgeDark}
        >{`${dimensions[0]} x ${dimensions[1]} см`}</StyledText>
      </Container>
    </Wrapper>
  );
};
