import styled, { useTheme } from "styled-components";
import { ReactComponent as SearchIcon } from "../../assets/search_icon.svg";
import { media } from "../../styles/media";
import { Text, TextType } from "../text/Text";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${media.tablet`
    width: 100%;
    align-items: flex-start;
    & > span {
      display: none;
    }
  `}
`;
const InputWrapper = styled.div`
  display: flex;
  position: relative;
  ${media.tablet`
    width: 100%;
  `}
`;
const Input = styled.input`
  width: 264px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid;
  padding: 4px 26px 4px 0;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter";
  color: ${(props: any) => props.theme.colors.black};
  &::placeholder {
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Inter";
    color: ${(props: any) => props.theme.colors.black};
  }
  ${media.tablet`
    width: 100%;
    padding: 8px 0 8px 40px;
    border: none;
    &::placeholder {
      text-align: left;
    }
  `}
`;
const Icon = styled(SearchIcon)`
  position: absolute;
  top: 7px;
  right: 0;

  ${media.tablet`
    width: 32px;
    height: 32px;
    top: 4px;
    left: 0;
  `}
`;

export const SearchBar = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle } = theme.colors;
  return (
    <Wrapper>
      <InputWrapper>
        <Input
          placeholder="Найти"
          value={value}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
        />
        <Icon />
      </InputWrapper>
      <Text
        type={TextType.TEXT_12_REGULAR}
        color={badgeMiddle}
        lineHeight={"12px"}
        margin={"8px 0 0 0"}
      >
        {"Например: bottega"}
      </Text>
    </Wrapper>
  );
};
