import styled, { useTheme } from "styled-components";

import { CommonButton } from "../../../../../components/commonButton/CommonButton";
import { Text, TextType } from "../../../../../components/text/Text";
import { DataItem } from "../../../../general/main/components/mainDiscounts/components/dataItem/DataItem";
import { media } from "../../../../../styles/media";
import { GoodType } from "../../../../../api/products";
import { LoaderBlock } from "../../../../../components/loaderBlock/LoaderBlock";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
  position: relative;
  ${media.desktop`
    padding: 0 24px;
  `}
`;
const List = styled.ul<any>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  padding: 0;
  margin-bottom: ${(props: any) =>
    props.isLoadMoreButtonDisplayed ? "0" : "240px"};

  & > div > div > span {
    color: ${(props: any) => props.theme.colors.black};
    &:hover {
      color: ${(props: any) => props.theme.colors.black};
    }
  }
  ${media.desktop`
    display: flex;
    flex-wrap: wrap;
  `}
  ${media.tablet`
    justify-content: space-between;
  `}
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 88px 0 240px 0;

  ${media.tablet`
    margin: 80px 0 120px 0;
  `}
`;
const Title = styled(Text)`
  ${media.desktop`
    font-size: 40px;
    line-height: 48px;
  `}
`;

export const SimilarProducts = ({
  list,
  isLoadingSimilarProductsInProgress,
  isLoadMoreButtonDisplayed,
  loadMoreOnClick,
  similarItemOnClick,
  addItemToChosenGoods,
  removeItemFromChosenGoods,
}: {
  list: GoodType[];
  isLoadingSimilarProductsInProgress: boolean;
  isLoadMoreButtonDisplayed: boolean;
  loadMoreOnClick: () => void;
  similarItemOnClick: (id: string) => void;
  addItemToChosenGoods: (item: GoodType) => void;
  removeItemFromChosenGoods: (id: string) => void;
}) => {
  const theme: any = useTheme();
  const { black, white } = theme.colors;

  return (
    <Wrapper>
      {isLoadingSimilarProductsInProgress && <LoaderBlock />}
      <Title type={TextType.TITLE_H1} color={black} margin={"0 0 32px 0"}>
        {"Похожие продукты"}
      </Title>
      <List isLoadMoreButtonDisplayed={isLoadMoreButtonDisplayed}>
        {list.map((item: GoodType) => {
          const { id } = item;
          return (
            <DataItem
              key={id}
              data={item}
              dataItemOnClick={() => {
                similarItemOnClick(id);
              }}
              addItemToChosenGoods={() => {
                addItemToChosenGoods(item);
              }}
              removeItemFromChosenGoods={() => {
                removeItemFromChosenGoods(id);
              }}
              isBigItemSize
            />
          );
        })}
      </List>
      {isLoadMoreButtonDisplayed && (
        <ButtonWrapper>
          <CommonButton
            value={"ЗАГРУЗИТЬ ЕЩЕ"}
            onClick={loadMoreOnClick}
            width={"416px"}
            backgroundColor={"transparent"}
            color={black}
            borderColor={black}
            backgroundColorHover={black}
            borderColorHover={black}
            colorHover={white}
            isLoadingInProcess={isLoadingSimilarProductsInProgress}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};
