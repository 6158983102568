import axios, { AxiosResponse } from "axios";

export enum ApiCallType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

const httpRequestMatch = {
  [ApiCallType.GET]: { call: axios.get, withoutBody: true },
  [ApiCallType.POST]: { call: axios.post },
  [ApiCallType.PATCH]: { call: axios.patch },
  [ApiCallType.PUT]: { call: axios.put },
  [ApiCallType.DELETE]: { call: axios.delete },
};

export interface HttpRequest {
  call: (url: string, body?: any, options?: any) => Promise<AxiosResponse<any>>;
  withoutBody?: boolean;
}

export const getHttpRequest = (callType: ApiCallType): HttpRequest =>
  httpRequestMatch[callType];

export interface HttpServiceGenerationOptions {
  url: string;
  body?: any;
  callType: ApiCallType;
}

export const getHttpService = ({
  callType,
  url,
  body = {},
}: HttpServiceGenerationOptions) => {
  const httpService = getHttpRequest(callType);
  const { call: httpServiceCall, withoutBody } = httpService;
  const optionsWithBody = [url, body] as const;
  const optionsWithoutBody = [url] as const;
  return withoutBody
    ? () => httpServiceCall(...optionsWithoutBody)
    : () => httpServiceCall(...optionsWithBody);
};
