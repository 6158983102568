import styled from "styled-components";
import { GoodType } from "../../../../api/products";
import {
  ConrollerItem,
  ControllerItemType,
} from "./components/controllerItem/ControllerItem";

const Wrapper = styled.div`
  width: 24px;
  height: 387px;
  box-sizing: border-box;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 376px;
  right: 16px;
  opacity: 1;
  transition: all 0.2s linear;

  &.hidden {
    opacity: 0;
  }
`;

export const Controllers = ({
  chosenGoods,
  scrollValue,
  maxScroll,
  topOnClick,
  bottomOnClick,
}: {
  chosenGoods: GoodType[];
  scrollValue: number;
  maxScroll: number;
  topOnClick: () => void;
  bottomOnClick: () => void;
}) => {
  return (
    <Wrapper className={chosenGoods.length < 4 ? "hidden" : ""}>
      <ConrollerItem
        type={ControllerItemType.TOP}
        onClick={topOnClick}
        className={scrollValue <= 0 || chosenGoods.length < 4 ? "disabled" : ""}
      />
      <ConrollerItem
        type={ControllerItemType.BOTTOM}
        onClick={bottomOnClick}
        className={
          scrollValue >= maxScroll || chosenGoods.length < 4 ? "disabled" : ""
        }
      />
    </Wrapper>
  );
};
