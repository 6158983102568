import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../consts";
import { GoodType } from "./products";
import { parse, stringifyUrl } from "query-string";
import { useLocation } from "react-router-dom";
import { useApi } from "../features/apiBuilder";

export interface CollectionType {
  brandId: string;
  categories: string[];
  formats: string[];
  id: string;
  images: string[];
  logo: string;
  name: string;
  possibleDimensions: number[][];
  products: GoodType[];
  readableCategory: string;
  readableSurfaceType: string;
  readableType: string;
  section: string;
}

const GET_COLLECTIONS_KEYWORD = "GET_COLLECTIONS_KEYWORD";

export const useGetCollectionsList = (
  section: string,
  limit: number,
  setIsLoadingDataInProcess?: (value: boolean) => void
) => {
  const { search } = useLocation();

  const url = stringifyUrl({
    url: `${API_URL}/collections`,
    query: { section, limit, ...parse(search) },
  });

  const [localData, , isLoading] = useApi({
    url,
    keywords: [GET_COLLECTIONS_KEYWORD],
    transformResponse: ({ payload }) => payload,
  });

  return { collections: (localData || []) as CollectionType[], isLoading };
};

export const useGetCollectionData = (
  id: string,
  setIsLoadingDataInProcess?: (value: boolean) => void
) => {
  const [localData, setLocalData] = useState<any>({});

  useEffect(() => {
    axios
      .get(`${API_URL}/collections/${id}`)
      .then((res) => {
        const { data } = res;
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        setLocalData(data.payload);
      })
      .catch((error) => {
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        console.log("error: ", error);
      });
  }, [id]);

  return localData;
};

export const useGetOtherCollections = (
  id: string,
  limit: number,
  setIsLoadingDataInProcess?: (value: boolean) => void
) => {
  const [localData, setLocalData] = useState<CollectionType[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/collections/${id}/other?limit=${limit}`)
      .then((res) => {
        const { data } = res;
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        setLocalData(data.payload);
      })
      .catch((error) => {
        setIsLoadingDataInProcess && setIsLoadingDataInProcess(false);
        console.log("error: ", error);
      });
  }, [id]);

  return localData;
};
