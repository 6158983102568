import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../components/text/Text";
import { View } from "../../../../../routes/routeInterfaces";
import { useCustomHistory } from "../../../../../utils/react-router-dom-abstraction";
import tileImg from "../../assets/tile2.png";
import furnitureImg from "../../assets/furniture2.png";
import lightImg from "../../assets/light.png";
import kitchensImg from "../../assets/kitchen.png";
import plumbingImg from "../../assets/plumbing.png";
import {
  CatalogItem,
  CatalogItemType,
} from "./components/catalogItem/CatalogItem";
import { CommonButton } from "../../../../../components/commonButton/CommonButton";
import { media } from "../../../../../styles/media";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 240px;
  ${media.tablet`
    display: none;
  `}
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Section = styled.div`
  display: flex;
  gap: 32px;
  justify-content: flex-start;
  margin-bottom: 32px;
  &.right {
    justify-content: flex-end;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 32px 0 240px 0;
`;

export const MainCatalog = () => {
  const theme: any = useTheme();
  const { white1 } = theme.colors;
  const history = useCustomHistory();
  const catalogArr = [
    {
      title: "Плитка",
      img: tileImg,
      to: { section: "tiles", limit: 12 },
      width: "416px",
    },
    {
      title: "Сантехника",
      img: plumbingImg,
      to: { section: "plumbing", limit: 12 },
      width: "640px",
    },
    {
      title: "Мебель",
      img: furnitureImg,
      to: { section: "furniture", limit: 12 },
      width: "640px",
    },
    {
      title: "Свет",
      img: lightImg,
      to: { section: "light", limit: 12 },
      width: "416px",
    },
    {
      title: "Кухни",
      img: kitchensImg,
      to: { section: "kitchen", limit: 12 },
      width: "416px",
    },
  ];
  const firstSection = catalogArr.slice(0, 2);
  const secondSection = catalogArr.slice(2, 3);
  const thirdSection = catalogArr.slice(3, 5);

  const catalogItemOnClick = (to: { section: string; limit: number }) => {
    const { section, limit } = to;
    history.push(View.PRODUCTS, { pathParams: { section, limit } });
  };
  const goToCatalog = () => {
    history.push(View.PRODUCTS, {
      pathParams: { section: "tiles", limit: 12 },
    });
  };

  return (
    <Wrapper>
      <Text type={TextType.TITLE_H1} color={white1} margin={"0 0 32px 0"}>
        {"Каталог"}
      </Text>
      <Container>
        <Section>
          {firstSection.map((section: CatalogItemType, index: number) => (
            <CatalogItem
              key={index}
              data={section}
              onClick={() => {
                catalogItemOnClick(section.to);
              }}
            />
          ))}
        </Section>
        <Section>
          {secondSection.map((section: CatalogItemType, index: number) => (
            <CatalogItem
              key={index}
              data={section}
              onClick={() => {
                catalogItemOnClick(section.to);
              }}
            />
          ))}
        </Section>
        <Section className={"right"}>
          {thirdSection.map((section: CatalogItemType, index: number) => (
            <CatalogItem
              key={index}
              data={section}
              onClick={() => {
                catalogItemOnClick(section.to);
              }}
            />
          ))}
        </Section>
        <ButtonWrapper>
          <CommonButton
            value={"ПЕРЕЙТИ В КАТАЛОГ"}
            onClick={goToCatalog}
            width={"416px"}
            backgroundColor={"transparent"}
            color={white1}
            borderColor={white1}
          />
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};
