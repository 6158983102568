import styled from "styled-components";
import { ReactComponent as Icon } from "../../../../../../../assets/fulled_heart.svg";

const Wrapper = styled.button<any>`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 22px;
  z-index: 10;
  background-color: ${(props: any) => props.theme.colors.white1};
  transition: all 0.3s linear;

  &:hover {
    background-color: ${(props: any) => props.theme.colors.badgeMiddle};
  }

  & > svg {
    width: 32px;
    height: 32px;
    & > path {
      stroke: ${(props: any) => props.theme.colors.black};
      fill: ${(props: any) =>
        props.isChoosen ? props.theme.colors.black : "transparent"};
    }
  }
`;

export const LikeButton = ({
  onClick,
  isChoosen,
  ...props
}: {
  onClick: () => void;
  isChoosen?: boolean;
  className?: string;
}) => {
  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
      isChoosen={isChoosen}
      {...props}
    >
      <Icon />
    </Wrapper>
  );
};
