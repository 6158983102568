import { FiltersItem } from "./components/filtersItem/FiltersItem";
import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../components/text/Text";
import { useFilterContext } from "../../filter.context";
import { useCurrentView } from "../../../../routes/currentView";

const ResetItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 32px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};

  & > span {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
    & > span {
      font-weight: 600;
    }
  }
`;

export const Filters = () => {
  const theme: any = useTheme();
  const { black } = theme.colors;
  const [, , { getActiveFilters, reset }] = useFilterContext();
  const { isGoods } = useCurrentView();

  const filters = getActiveFilters(isGoods ? "products" : "collections");
  return (
    <>
      {filters.map((item) => {
        return (
          <FiltersItem
            key={item.filterKey}
            data={item}
            mode={isGoods ? "products" : "collections"}
          />
        );
      })}
      {reset && (
        <ResetItem onClick={() => reset(isGoods ? "products" : "collections")}>
          <Text type={TextType.TEXT_14_REGULAR} color={black}>
            {"СБРОСИТЬ ФИЛЬТРЫ"}
          </Text>
        </ResetItem>
      )}
    </>
  );
};
