import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../components/text/Text";
import { ReactComponent as CIcon } from "../../assets/c_icon.svg";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { GeneralView } from "../../enums/View";
import { media } from "../../styles/media";
import { useWindowSize } from "../../styles/style.context";
import { Logo, LogoType } from "../logo/Logo";
import { MenuArrType } from "../header/components/menu/Menu";
import { CenteredWrapper } from "../header/Header";

const Wrapper = styled.div<any>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  border-top: 1px solid;
  border-color: ${(props: any) =>
    props.borderColor ? props.borderColor : props.theme.colors.gray4};
`;
const Center = styled(CenteredWrapper)`
  padding: 40px 64px 24px 64px;
  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 24px;
  `}
`;
const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.tablet`
    width: 100%;
  `}
`;
const YearWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  & > svg > g > path {
    stroke: ${(props: any) => props.color};
  }
  ${media.tablet`
    &.mobile {
        margin: 8px 0 0 0;
    }
  `}
`;
const MenuList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 64px 0;
  padding: 0;
  gap: 32px;
  & > span {
    width: max-content;
    transition: all 0.3s linear;
    border-bottom: 1px solid;
    border-color: transparent;
  }
  & > span:hover {
    border-color: ${(props: any) => props.color};
  }
  & > span.active {
    border-color: ${(props: any) => props.color};
    pointer-events: none;
  }
  ${media.tabletS`
    flex-direction: column;
    margin: 0 0 120px 0;
  `}
`;
const UpButton = styled(Text)`
  text-decoration: underline;
`;
const ContactText = styled(Text)`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  ${media.tabletS`
    &.email {
        margin: 0 0 32px 0;
    }
    margin: 0 0 120px 0;
    text-decoration: underline;
  `}
`;
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.tablet`
    flex-direction: row;
    align-items: center;
    margin-bottom: 64px;
  `}
`;

export const Footer = ({
  menuArr,
  currentPath,
  backgroundColor,
  borderColor,
}: {
  menuArr: MenuArrType[];
  currentPath: string;
  backgroundColor?: string;
  borderColor?: string;
}) => {
  const theme: any = useTheme();
  const { gray4, gray3, black, white1, badgeDark, badgeLight } = theme.colors;
  const history = useCustomHistory();
  const windowWidth = useWindowSize()[0];
  const color = currentPath.includes("main") ? white1 : black;
  const contactTextColor = currentPath.includes("main") ? badgeLight : black;

  const menuItemOnClick = (to: GeneralView) => {
    history.push(to);
  };
  const upButtonOnClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Wrapper backgroundColor={backgroundColor} borderColor={borderColor}>
      <Center>
        <Column>
          <LogoWrapper>
            <Logo type={LogoType.SMALL} color={color} />
            {windowWidth < 822 && (
              <UpButton
                type={TextType.TEXT_14_REGULAR}
                color={
                  currentPath.includes("brands") ||
                  currentPath.includes("cooperation")
                    ? badgeDark
                    : gray4
                }
                hoverColor={
                  currentPath.includes("brands") ||
                  currentPath.includes("cooperation")
                    ? black
                    : gray3
                }
                onClick={upButtonOnClick}
              >
                {"ВВЕРХ"}
              </UpButton>
            )}
          </LogoWrapper>
          {windowWidth > 822 && (
            <YearWrapper color={color}>
              <CIcon />
              <Text
                type={TextType.TEXT_14_REGULAR}
                color={color}
                margin={"0 0 0 4px"}
              >
                {"Позитано 2022"}
              </Text>
            </YearWrapper>
          )}
        </Column>
        <Column>
          <MenuList color={color}>
            {menuArr.map((item: MenuArrType, index: number) => {
              const { name, to, id } = item;

              return (
                <Text
                  key={index}
                  className={currentPath.includes(id) ? "active" : ""}
                  type={TextType.TEXT_14_REGULAR}
                  color={color}
                  onClick={() => {
                    menuItemOnClick(to);
                  }}
                >
                  {name}
                </Text>
              );
            })}
          </MenuList>
          <Column>
            <ContactText
              className={"email"}
              type={TextType.TITLE_H2}
              color={contactTextColor}
              margin={windowWidth < 1026 ? "0 0 12px 0" : "0"}
            >
              {"positanogrupp@gmail.com"}
            </ContactText>
            <ContactText
              type={TextType.TITLE_H2}
              color={contactTextColor}
              margin={windowWidth < 1026 ? "0 0 24px 0" : "0 0 64px 0"}
            >
              {"+375 (44) 799-70-70"}
            </ContactText>
          </Column>
          <Text
            type={TextType.TEXT_16_REGULAR}
            color={
              currentPath.includes("brands") ||
              currentPath.includes("cooperation")
                ? badgeDark
                : gray4
            }
            lineHeight={"16px"}
          >
            {"Политика конфиденциальности"}
          </Text>
          {windowWidth < 823 && (
            <YearWrapper className={"mobile"} color={color}>
              <CIcon />
              <Text
                type={TextType.TEXT_14_REGULAR}
                color={color}
                margin={"0 0 0 4px"}
              >
                {"Позитано 2022"}
              </Text>
            </YearWrapper>
          )}
        </Column>
        <Column>
          {windowWidth > 822 && (
            <UpButton
              type={TextType.TEXT_14_REGULAR}
              color={
                currentPath.includes("brands") ||
                currentPath.includes("cooperation")
                  ? badgeDark
                  : gray4
              }
              hoverColor={
                currentPath.includes("brands") ||
                currentPath.includes("cooperation")
                  ? black
                  : gray3
              }
              onClick={upButtonOnClick}
            >
              {"ВВЕРХ"}
            </UpButton>
          )}
        </Column>
      </Center>
    </Wrapper>
  );
};
