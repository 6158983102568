import styled, { useTheme } from "styled-components";
import { media } from "../../../../styles/media";
import { ReactComponent as FiltersIcon } from "../../../../assets/settings_icon.svg";
import { MobileFiltersModal } from "./components/mobileFiltersModal/MobileFiltersModal";

const Wrapper = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    justify-content: flex-start;
    border-bottom: 1px solid;
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  `}
`;

export const MobileFilters = ({
  isOpenMobileFiltersModal,
  openFiltersModal,
  closeFiltersModal,
  readyButtonOnClick,
  resetFilterButtonOnClick,
}: {
  isOpenMobileFiltersModal: boolean;
  openFiltersModal: () => void;
  closeFiltersModal: () => void;
  readyButtonOnClick: () => void;
  resetFilterButtonOnClick: () => void;
}) => {
  return (
    <Wrapper>
      <FiltersIcon onClick={openFiltersModal} />
      <MobileFiltersModal
        isOpenModal={isOpenMobileFiltersModal}
        closeModal={closeFiltersModal}
        readyButtonOnClick={readyButtonOnClick}
        resetFilterButtonOnClick={resetFilterButtonOnClick}
      />
    </Wrapper>
  );
};
