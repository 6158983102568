import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { CollectionType, useGetCollectionsList } from "../../api/collections";
import {
  selectIsLoadingDataInProcess,
  setIsLoadingData,
} from "../../app/state/goodsSlice";
import { CommonButton } from "../../components/commonButton/CommonButton";
import { Text, TextType } from "../../components/text/Text";
import { Goods } from "../../layouts/goods/Goods";
import { View } from "../../routes/routeInterfaces";
import { media } from "../../styles/media";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { CollectionsList } from "./components/collectionsList/CollectionsList";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > span.goods-count {
    display: none;
  }

  ${media.tablet`
    & > span.goods-count {
      display: flex;
      margin-bottom: 24px;
    }
  `}
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 88px;
  ${media.tablet`
    margin-top: 80px;
  `}
  ${media.tabletS`
    & > button > div {
      right: 27%;
    }
  `}
  ${media.phone`
    & > button > div {
      right: 25%;
    }
  `}
  ${media.phoneS`
    & > button > div {
      right: 23%;
    }
  `}
  ${media.phoneXS`
    & > button > div {
      right: 19%;
    }
  `}
`;

export const Collections = ({ match }: { match: any }) => {
  const theme: any = useTheme();
  const currentPath = match.path;
  const pathParams = match.params;
  const history = useCustomHistory();
  const [dataOffset, setDataOffset] = useState(12);
  const offsetStep = 12;

  const { isLoading, collections } = useGetCollectionsList(
    pathParams.section,
    pathParams.limit
  );
  const dataLength = collections.length;

  const isLoadMoreButtonDisplayed = dataOffset < dataLength;
  const toDetails = View.COLLECTIONS_DETAILS;
  const preparedData = collections.map((item: CollectionType, index: number) =>
    index === 0 || index % 3 === 0
      ? { ...item, width: "100%", height: "535px" }
      : { ...item, width: "316px", height: "316px" }
  );

  const { white1, black, badgeMiddle } = theme.colors;

  const loadMoreOnClick = () => {
    setTimeout(() => {
      setDataOffset(dataOffset + offsetStep);
    }, 3000);
  };
  const listItemOnClick = (id: string) => {
    history.push(toDetails, { pathParams: { id } });
  };

  return (
    <Goods
      currentPath={currentPath}
      pageIndicator={pathParams.section}
      fullDataListLength={dataLength}
      isLoadingDataInProcess={isLoading}
    >
      <Wrapper>
        <Text
          type={TextType.TEXT_12_REGULAR}
          color={badgeMiddle}
          lineHeight={"12px"}
          className={"goods-count"}
        >
          {`${dataLength} товаров`}
        </Text>
        <CollectionsList
          data={preparedData}
          listItemOnClick={listItemOnClick}
        />
        {isLoadMoreButtonDisplayed && (
          <ButtonWrapper>
            <CommonButton
              value={"ЗАГРУЗИТЬ ЕЩЕ"}
              onClick={loadMoreOnClick}
              width={"416px"}
              backgroundColor={"transparent"}
              backgroundColorHover={black}
              color={black}
              colorHover={white1}
              borderColor={black}
              isLoadingInProcess={isLoading}
            />
          </ButtonWrapper>
        )}
      </Wrapper>
    </Goods>
  );
};
