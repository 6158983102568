import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";

export interface InfoItemType {
  objectId: string;
  label: string;
  value: string;
}

const Wrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0 0 48px 0;
`;
const Item = styled.li`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 166px 1fr;
  grid-gap: 32px;
  padding: 16px 0;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};

  &.last {
    border: none;
  }

  ${media.desktop`
    padding: 16px 24px;
  `}
`;

export const InfoList = ({ arr }: { arr: InfoItemType[] }) => {
  const theme: any = useTheme();
  const { badgeMiddle, black } = theme.colors;

  return (
    <Wrapper>
      {arr &&
        arr.map((item: InfoItemType, index: number) => {
          const { label, value, objectId } = item;

          return (
            <Item
              key={index}
              className={index === arr.length - 1 ? "last" : ""}
            >
              <Text
                type={TextType.TEXT_16_REGULAR}
                color={badgeMiddle}
              >{`${label}: `}</Text>
              <Text type={TextType.TEXT_16_REGULAR} color={black}>
                {`${value} ${objectId === "size" ? "см" : ""}`}
              </Text>
            </Item>
          );
        })}
    </Wrapper>
  );
};
