import styled from "styled-components";
import { ReactComponent as Icon } from "../../../../../../../../assets/tick.svg";

const Wrapper = styled.div<any>`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: any) => props.backgroundColor};
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  border-radius: 50%;

  & > svg {
    opacity: 0;
    transition: all 0.3s linear;
    & > path {
      stroke: ${(props: any) => (props.iconColor ? props.iconColor : "white")};
    }
  }
  &.selected > svg {
    opacity: 1;
  }
`;

export const ColorOption = ({
  isSelected,
  backgroundColor,
  iconColor,
}: {
  isSelected: boolean;
  backgroundColor: string;
  iconColor: string;
}) => {
  return (
    <Wrapper
      className={isSelected ? "selected" : ""}
      backgroundColor={backgroundColor}
      iconColor={iconColor}
    >
      {<Icon />}
    </Wrapper>
  );
};
