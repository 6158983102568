import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/long_arrow.svg";

export enum ControllerItemType {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

const Wrapper = styled.button`
  width: 24px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 16px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  background-color: ${(props: any) => props.theme.colors.white1};
  cursor: pointer;
  transition: all 0.3s linear;

  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.badgeMiddle};
      transition: all 0.3s linear;
    }
  }
  &.disabled {
    cursor: auto;
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
    pointer-events: none;
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.badgeMiddle};
      transition: all 0.3s linear;
    }
  }
  &.top {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const ConrollerItem = ({
  type,
  onClick,
  className,
}: {
  type: ControllerItemType;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <Wrapper
      className={`${
        type === ControllerItemType.TOP
          ? "top"
          : type === ControllerItemType.BOTTOM
          ? "bottom"
          : ""
      } ${className}`}
      onClick={onClick}
    >
      {<ArrowIcon />}
    </Wrapper>
  );
};
