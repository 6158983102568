// @ts-ignore
import styled, { useTheme } from "styled-components";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/ArrowDown.svg";
import { Text, TextType } from "../../../../components/text/Text";
import { useRef, useState } from "react";
import { useOutsideHandler } from "../../../../utils/outsideHandler";
import { useCustomHistory } from "../../../../utils/react-router-dom-abstraction";
import { MenuArrType } from "../menu/Menu";
import { View } from "../../../../routes/routeInterfaces";

interface MenuItemType {
  isOpenSectionsList: boolean;
  withLargeLogo: boolean;
  isCatalogItem: boolean;
  theme: any;
}

const Item = styled.li<MenuItemType>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: ${(props: MenuItemType) =>
    props.isOpenSectionsList ? "none" : "border 0.3s linear"};
  background-color: ${(props: MenuItemType) =>
    props.isOpenSectionsList ? props.theme.colors.gray4 : "transparent"};
  cursor: ${(props: any) => (props.isOpenSectionsList ? "auto" : "pointer")};
  margin-right: 64px;
  position: relative;
  &.last {
    margin: 0;
  }
  &.active {
    border-color: ${(props: MenuItemType) =>
      props.isOpenSectionsList ? "transparent" : props.theme.colors.gray1};
    pointer-events: ${(props: any) => (props.isCatalogItem ? "auto" : "none")};
  }
  &:hover {
    border-bottom: 1px solid;
    border-color: ${(props: MenuItemType) =>
      props.withLargeLogo
        ? props.theme.colors.white1
        : props.isOpenSectionsList
        ? "transparent"
        : props.theme.colors.black};
    transition: ${(props: MenuItemType) =>
      props.isOpenSectionsList ? "none" : "border 0.3s linear"};
  }
  & > span {
    cursor: pointer;
    position: relative;
    z-index: 10;
  }
  & > svg {
    transform: ${(props: MenuItemType) =>
      props.isOpenSectionsList ? "rotate(180deg)" : "rotate(0deg)"};
    transition: all 0.3s linear;
  }
`;
const ArrowIcon = styled(ArrowDownIcon)`
  margin-left: 4px;
  position: relative;
  z-index: 10;
  & > path {
    fill: ${(props: any) => props.color};
  }
`;
const SectionsList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 50px 16px 16px 16px;
  position: absolute;
  z-index: 1;
  background-color: ${(props: any) => props.theme.colors.gray4};
  top: -16px;
  left: -16px;
`;
const SectionItem = styled(Text)<any>`
  width: max-content;
  border-bottom: 1px solid;
  border-color: transparent;
  cursor: pointer;

  &.active {
    border-color: ${(props: MenuItemType) =>
      props.isOpenSectionsList ? "transparent" : props.theme.colors.gray1};
    pointer-events: none;
  }

  &:hover {
    border-color: ${(props: MenuItemType) =>
      props.isOpenSectionsList ? "transparent" : props.theme.colors.gray1};
  }
`;

export const MenuItem = ({
  data,
  currentPath,
  className,
}: {
  data: MenuArrType;
  currentPath: string;
  className?: string;
}) => {
  const [isOpenSectionsList, setIsOpenSectionsList] = useState(false);
  const { sections, name, to, id } = data;
  const theme: any = useTheme();
  const { white1, black } = theme.colors;
  const withArrow = !!sections && sections.length > 0;
  const sectionsRef = useRef<any>(null);
  const history = useCustomHistory();

  const menuItemOnClick = () => {
    if (sections && sections.length > 0) {
      setIsOpenSectionsList(!isOpenSectionsList);
    } else {
      history.push(to);
    }
  };
  const sectionItemOnClick = (id: string) => {
    history.push(View.PRODUCTS, { pathParams: { section: id, limit: 12 } });
  };

  useOutsideHandler(sectionsRef, () => {
    setIsOpenSectionsList(false);
  });

  return (
    <Item
      onClick={menuItemOnClick}
      isOpenSectionsList={isOpenSectionsList && withArrow}
      theme={theme}
      className={`${currentPath.includes(id) ? "active" : ""} ${className}`}
      isCatalogItem={!!(sections && sections.length > 0)}
      withLargeLogo={currentPath.includes("main")}
    >
      <Text
        type={TextType.TEXT_14_REGULAR}
        color={
          withArrow && isOpenSectionsList
            ? black
            : currentPath.includes("main")
            ? white1
            : black
        }
      >
        {name.toUpperCase()}
      </Text>
      {withArrow && (
        <ArrowIcon
          color={
            isOpenSectionsList || !currentPath.includes("main") ? black : white1
          }
        />
      )}
      {withArrow && isOpenSectionsList && (
        <SectionsList ref={sectionsRef}>
          {sections.map(
            (
              el: {
                id: string;
                name: string;
              },
              i: number
            ) => {
              return (
                <SectionItem
                  key={i}
                  onClick={() => {
                    sectionItemOnClick(el.id);
                  }}
                  type={TextType.TEXT_16_REGULAR}
                  margin={i === sections.length - 1 ? "0" : "0 0 8px 0"}
                  className={currentPath.includes(el.id) ? "active" : ""}
                >
                  {el.name}
                </SectionItem>
              );
            }
          )}
        </SectionsList>
      )}
    </Item>
  );
};
