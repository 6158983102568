import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { CollectionType } from "../../../../../api/collections";
import { LoaderBlock } from "../../../../../components/loaderBlock/LoaderBlock";
import { Text, TextType } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { useWindowSize } from "../../../../../styles/style.context";
import { Controllers } from "../controllers/Controllers";
import { ImagesCarousel } from "../imagesCarousel/ImagesCarousel";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 0 0 32px;
  margin-bottom: 240px;
  position: relative;

  ${media.desktop`
    flex-direction: column;
    padding: 0;
    margin-bottom: 72px;
  `}
`;
const InfoWrapper = styled.div`
  width: 33%;
  min-width: 416px;
  display: flex;
  flex-direction: column;
  margin-right: 32px;

  & > span {
    word-break: break-word;
  }

  ${media.narrowMonitor`
    min-width: auto;
  `}
  ${media.desktop`
    width: 100%;
    & > span {
      font-size: 40px;
      line-height: 48px;
      margin: 0 0 32px 24px;
    }
  `}
`;
const Logo = styled.img`
  width: 200px;
  margin-bottom: 178px;
  ${media.desktop`
    display: none;
  `}
`;
const ParametersList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  ${media.tabletS`
    margin: 0;
  `}
`;
const ParameterItem = styled.li`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  ${media.desktop`
    padding: 24px;
    border-bottom: 1px solid;
    border-color: ${(props: any) => props.theme.colors.badgeMiddle};
    margin: 0;
  `}
`;
const StyledControllers = styled(Controllers)`
  ${media.tabletS`
    display: none;
  `}
`;

export const CollectionInfo = ({
  data,
  isLoadingDataInProcess,
}: {
  data: CollectionType;
  isLoadingDataInProcess?: boolean;
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const windowWidth = useWindowSize()[0];

  const theme: any = useTheme();
  const { black, badgeMiddle } = theme.colors;
  const {
    name,
    images,
    logo,
    readableCategory,
    readableType,
    readableSurfaceType,
  } = data;

  const parametersArr = [
    { label: "Категория", value: readableCategory },
    { label: "Поверхность", value: readableSurfaceType },
    { label: "Тип плитки", value: readableType },
  ];
  const swipableArea = windowWidth < 673 ? windowWidth - 16 : 672;

  const leftControllerOnClick = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const rightControllerOnClick = () => {
    if (images && currentImageIndex < images.length) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (
      images &&
      touchStart - touchEnd > 75 &&
      currentImageIndex < images.length - 1
    ) {
      rightControllerOnClick();
    }

    if (touchStart - touchEnd < -75 && currentImageIndex > 0) {
      leftControllerOnClick();
    }
  };

  return (
    <Wrapper>
      <InfoWrapper>
        <Text type={TextType.TITLE_H1} color={black}>
          {name ? name : "N/A"}
        </Text>
        {images && images.length > 0 && (
          <ImagesCarousel
            imagesArr={images}
            translateValue={currentImageIndex * swipableArea}
            className={"desktop"}
            logo={logo}
            handleTouchStart={handleTouchStart}
            handleTouchMove={handleTouchMove}
            handleTouchEnd={handleTouchEnd}
          />
        )}

        <Logo src={logo} alt={"logo"} />
        <ParametersList>
          {parametersArr.map(
            (item: { label: string; value: string }, index: number) => {
              const { label, value } = item;
              return (
                <ParameterItem key={index}>
                  <Text
                    type={TextType.TEXT_16_REGULAR}
                    color={badgeMiddle}
                    margin={"0 0 4px 0"}
                  >{`${label}: `}</Text>
                  <Text type={TextType.TEXT_16_REGULAR} color={black}>
                    {value}
                  </Text>
                </ParameterItem>
              );
            }
          )}
        </ParametersList>
        <StyledControllers
          currentValue={currentImageIndex + 1}
          maxValue={images ? images.length : 0}
          leftOnClick={leftControllerOnClick}
          rigtOnClick={rightControllerOnClick}
        />
      </InfoWrapper>
      {images && images.length > 0 && (
        <ImagesCarousel
          imagesArr={images}
          translateValue={currentImageIndex * 672}
          className={"monitor"}
        />
      )}
      {isLoadingDataInProcess && <LoaderBlock />}
    </Wrapper>
  );
};
