export const theme = {
  colors: {
    black: "#101010",
    white: "#ffffff",
    white1: "#F4F3EF",
    gray1: "#333333",
    gray3: "#828282",
    gray4: "#BDBDBD",
    gray5: "#E0E0E0",
    gray6: "#F2F2F2",
    badgeMiddle: "#C3C0B6",
    badgeDark: "#8C8A7F",
    badgeLight: "#f4f3ef",
    greenDark: "#41403B",
    green: "#69B94D",
    orange: "#FFB156",
  },
  shadows: {},
  borders: {},
  borderRadius: {},
};
