import React, { FC, useState } from "react";
import styled, { useTheme } from "styled-components";
import { motion } from "framer-motion";
import check from "../../assets/check.svg";

const Button = styled(motion.div)<any>`
  width: 18px;
  height: 18px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const InnerCircle = styled(motion.div)`
  width: auto;
  height: auto;
  display: flex;
  border-radius: 8px;
  box-shadow: none;
  justify-content: center;
  align-items: center;
`;

export interface CheckBoxProps {
  toggle?: boolean;
  disabled?: boolean;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  className?: string;
}

export const CheckBox: FC<CheckBoxProps> = ({
  toggle,
  handleToggle,
  disabled = false,
  color,
  backgroundColor,
  backgroundColorHover,
  ...props
}) => {
  const theme: any = useTheme();
  const [disabledState] = useState(disabled);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleToggle(e);
  };

  return (
    <Button
      onClick={(e: any) => {
        disabled ? e.preventDefault() : handleClick(e);
      }}
      animate={{
        backgroundColor: toggle
          ? backgroundColorHover
            ? backgroundColorHover
            : theme.colors.primary
          : backgroundColor
          ? backgroundColor
          : theme.colors.white,
        opacity: disabledState ? 0.3 : 1,
      }}
      transition={{ duration: 0.1, ease: "linear" }}
      {...props}
    >
      <InnerCircle
        animate={{
          opacity: toggle ? 1 : 0,
        }}
        transition={{ damping: 0 }}
        theme={theme}
      >
        <img src={check} alt="c" />
      </InnerCircle>
    </Button>
  );
};
