import { SyntheticEvent, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../../components/text/Text";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/ArrowDown.svg";
import { OptionItem } from "../optionItem/OptionItem";
import { Filter, FilterMode } from "../../../../types";
import { useFilterContext } from "../../../../filter.context";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 32px;
  box-sizing: border-box;
`;
const Arrow = styled(ArrowIcon)`
  transition: all 0.3s linear;

  &.open {
    transform: rotate(180deg);
  }
`;
const OptionsList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 32px 32px 32px;
  box-sizing: border-box;
  list-style: none;
  gap: 8px;

  &.wrap {
    width: 66%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const StyledText = styled(Text)`
  cursor: pointer;
`;

export const FiltersItem = ({
  data,
  mode,
}: {
  data: Filter;
  mode: FilterMode;
}) => {
  const theme: any = useTheme();
  const { black } = theme.colors;
  const [isOpenList, setIsOpenList] = useState(false);

  const [, , { onMultiChange, onSingleChange }] = useFilterContext();

  const { label, options, filterType, filterKey } = data;

  const titleOnClick = () => {
    setIsOpenList(!isOpenList);
  };

  const optionItemOnClick = (
    type: string,
    item: { name: string; id: string }
  ) => {
    if (type === "single-select") {
      onSingleChange(item.id, mode, filterKey);
    } else {
      onMultiChange(item.id, mode, filterKey);
    }
  };

  return (
    <Wrapper>
      <TitleWrapper onClick={titleOnClick}>
        <StyledText type={TextType.TEXT_14_REGULAR} color={black}>
          {label.toUpperCase()}
        </StyledText>
        <Arrow className={isOpenList ? "open" : ""} />
      </TitleWrapper>
      {isOpenList && (
        <OptionsList
          className={filterType === "color-multi-select" ? "wrap" : ""}
          onClick={(e: SyntheticEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {options.map((option) => {
            const { id, isSelected } = option;
            return (
              <OptionItem
                key={id}
                type={filterType}
                value={option}
                isSelected={!!isSelected}
                onClick={() => {
                  optionItemOnClick(filterType, option);
                }}
              />
            );
          })}
        </OptionsList>
      )}
    </Wrapper>
  );
};
