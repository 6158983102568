import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { VIEW_PATH_PAIRS } from "./paths";
import { View } from "./routeInterfaces";
import { GeneralView } from "../enums/View";

export const useCurrentView = () => {
  const [currentView, setCurrentView] = useState(View.REDIRECT);
  const { pathname } = useLocation();

  useEffect(() => {
    const index = VIEW_PATH_PAIRS.findIndex(({ path }) => {
      const route = matchPath(pathname, path);

      return !!route;
    });

    index !== -1 && setCurrentView(VIEW_PATH_PAIRS[index].view as GeneralView);
  }, [pathname]);

  return {
    currentView,
    isGoods: currentView === View.PRODUCTS,
  };
};
