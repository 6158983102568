import styled, { useTheme } from "styled-components";
import { CollectionType } from "../../../../../../../api/collections";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";

const Wrapper = styled.div`
  width: 416px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    & > div > div.background {
      background-color: rgb(0, 0, 0, 0.3);
    }
  }
  ${media.tablet`
    width: 342px;
  `}
  ${media.phone`
    width: 100%;
  `}
`;
const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 28px;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.3s linear;
  background-color: transparent;
  z-index: 2;
`;
const Image = styled.img`
  width: 100%;
  height: 416px;
  position: relative;
  z-index: 1;

  ${media.tablet`
    height: 342px;
  `}
  ${media.phone`
    width: 100%;
  `}
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > span.size {
    width: max-content;
  }
`;
const DimensionsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  &.parent {
    gap: 8px;
  }
`;
const Title = styled(Text)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const Box = styled.div`
  display: flex;
`;

const Dimension = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CollectionItem = ({
  data,
  collectionItemOnClick,
  className,
}: {
  data: CollectionType;
  collectionItemOnClick: () => void;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { badgeDark, black } = theme.colors;
  const { id, images, name, possibleDimensions } = data;

  return (
    <Wrapper className={`${className}`} onClick={collectionItemOnClick}>
      <ImageWrapper>
        <Background className={"background"} />
        <Image src={images[0]} alt={id} />
      </ImageWrapper>
      <Container>
        <Title type={TextType.TITLE_H2} color={black}>
          {name}
        </Title>
        <DimensionsWrapper className={"parent"}>
          {
            /*possibleDimensions*/ [].map((item: any, index: number) => {
              return (
                <Box key={index}>
                  <DimensionsWrapper key={index}>
                    {item.map((el: any, i: number) => {
                      return (
                        <Dimension
                          key={i}
                          type={TextType.TEXT_16_REGULAR}
                          color={badgeDark}
                          margin={"0 3px 0 0"}
                        >
                          {`${el} ${i < item.length - 1 ? "x " : ""}`}
                        </Dimension>
                      );
                    })}
                  </DimensionsWrapper>
                  <Text
                    type={TextType.TEXT_16_REGULAR}
                    color={badgeDark}
                  >{` см${
                    index < possibleDimensions.length - 1 ? ", " : ""
                  }`}</Text>
                </Box>
              );
            })
          }
        </DimensionsWrapper>
      </Container>
    </Wrapper>
  );
};
