import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { selectChosenGoods } from "../../app/state/chosenGoodsSlice";

import { ReactComponent as HeartIcon } from "../../assets/fulled_heart.svg";
import { media } from "../../styles/media";
import { Text, TextType } from "../text/Text";
import { ChosenList } from "./components/chosenList/ChosenList";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 328px;
  right: 0;
  z-index: 1111;
  ${media.tablet`
    display: none;
  `}
`;
const Wrapper = styled.div`
  width: 58px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: any) => props.theme.colors.badgeDark};
  border-radius: 24px 0px 0px 24px;
  transition: width 0.3s linear;
  &.open {
    width: 434px;
    justify-content: space-between;
    padding: 0 16px;
  }
`;
const HeartContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  & > span {
    position: absolute;
    top: 15px;
  }
  &.ordered > span {
    right: 20px;
  }
  &.open {
    cursor: auto;
  }
`;
const Icon = styled(HeartIcon)`
  & > path {
    fill: transparent;
    stroke: ${(props: any) => props.theme.colors.black};
  }
  &.filled > path {
    fill: ${(props: any) => props.theme.colors.black};
  }
`;
const CloseButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover {
    border-color: ${(props: any) => props.theme.colors.greenDark};
    & > span {
      color: ${(props: any) => props.theme.colors.greenDark};
      transition: all 0.3s linear;
    }
  }
`;

export const ChoosenGoods = ({
  isOpenChosenGoods,
  openChosenGoods,
  closeChosenGoods,
  deleteChosenGood,
  openChosenGood,
}: {
  isOpenChosenGoods: boolean;
  openChosenGoods: () => void;
  closeChosenGoods: () => void;
  deleteChosenGood: (id: string) => void;
  openChosenGood: (id: string) => void;
}) => {
  const chosenGoods = useSelector(selectChosenGoods);
  const theme: any = useTheme();
  const { black } = theme.colors;
  const countOfChoosenGoods = chosenGoods.length;

  return (
    <Container>
      <Wrapper className={isOpenChosenGoods ? "open" : ""}>
        <HeartContainer
          className={`${isOpenChosenGoods ? "open" : ""} ${
            countOfChoosenGoods > 9 ? `ordered ` : ""
          }`}
          onClick={openChosenGoods}
        >
          <Icon className={countOfChoosenGoods > 0 ? "filled" : ""} />
          <Text type={TextType.TEXT_12_REGULAR} color={black}>
            {countOfChoosenGoods < 10 ? countOfChoosenGoods : "9+"}
          </Text>
        </HeartContainer>
        {isOpenChosenGoods && (
          <CloseButton onClick={closeChosenGoods}>
            <Text
              type={TextType.TEXT_14_REGULAR}
              color={black}
              onClick={(e: any) => {
                e.preventDefault();
              }}
            >
              {"Закрыть".toUpperCase()}
            </Text>
          </CloseButton>
        )}
      </Wrapper>
      <ChosenList
        chosenGoods={chosenGoods}
        isOpenChosenGoods={isOpenChosenGoods}
        deleteChosenGood={deleteChosenGood}
        openChosenGood={openChosenGood}
      />
    </Container>
  );
};
