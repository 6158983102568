import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../components/text/Text";
import { media } from "../../../../styles/media";

const Wrapper = styled.li<any>`
  width: ${(props: any) => (props.width ? props.width : "316px")};
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
  cursor: pointer;

  &:hover {
    & > div > div.background {
      background-color: rgb(0, 0, 0, 0.3);
    }
  }
  ${media.tablet`
    width: 342px;
    margin-bottom: 32px;
  `}
  ${media.phone`
    width: 100%;
  `}
`;
const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 24px;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.3s linear;
  background-color: transparent;
  z-index: 2;
`;
const Image = styled.img`
  width: 100%;
  height: ${(props: any) => (props.height ? props.height : "316px")};
  position: relative;
  z-index: 1;

  ${media.tablet`
    height: 342px;
  `}
`;
const Title = styled(Text)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const DimensionsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  &.parent {
    gap: 8px;
  }
`;
const Container = styled.div`
  display: flex;
`;

const Dimension = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CollectionItem = ({
  data,
  listItemOnClick,
}: {
  data: any;
  listItemOnClick: (id: string) => void;
}) => {
  const theme: any = useTheme();
  const { black, badgeDark } = theme.colors;
  const { id, images, width, name, height, possibleDimensions } = data;

  return (
    <Wrapper
      width={width}
      onClick={() => {
        listItemOnClick(id);
      }}
    >
      <ImageWrapper>
        <Background className={"background"} />
        <Image src={images[0]} alt={"IMG"} height={height} />
      </ImageWrapper>
      <Title type={TextType.TITLE_H2} color={black} margin={"0 0 24px 0"}>
        {name}
      </Title>
      <DimensionsWrapper className={"parent"}>
        {
          /*possibleDimensions*/ [].map((item: any, index: number) => {
            return (
              <Container key={index}>
                <DimensionsWrapper key={index}>
                  {item.map((el: any, i: number) => {
                    return (
                      <Dimension
                        key={i}
                        type={TextType.TEXT_16_REGULAR}
                        color={badgeDark}
                        margin={"0 3px 0 0"}
                      >
                        {`${el} ${i < item.length - 1 ? "x " : ""}`}
                      </Dimension>
                    );
                  })}
                </DimensionsWrapper>
                <Text type={TextType.TEXT_16_REGULAR} color={badgeDark}>{` см${
                  index < possibleDimensions.length - 1 ? ", " : ""
                }`}</Text>
              </Container>
            );
          })
        }
      </DimensionsWrapper>
    </Wrapper>
  );
};
