import { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../api/products";
import { Text, TextType } from "../../../text/Text";
import { ChosenItem } from "../chosenItem/ChosenItem";
import { Controllers } from "../controllers/Controllers";

const Wrapper = styled.ul<any>`
  width: 0;
  height: 0;
  max-height: 387px;
  box-sizing: border-box;
  background-color: ${(props: any) => props.theme.colors.white1};
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: all 0.3s linear;
  list-style: none;
  overflow-y: auto;
  & > span {
    display: none;
    opacity: 0;
  }
  &.open {
    height: ${(props: any) => props.height};
    width: 384px;
    opacity: 1;
    & > span {
      display: flex;
      opacity: 1;
    }
  }
  &.empty {
    height: 129px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ChosenList = ({
  chosenGoods,
  isOpenChosenGoods,
  deleteChosenGood,
  openChosenGood,
}: {
  chosenGoods: GoodType[];
  isOpenChosenGoods: boolean;
  deleteChosenGood: (id: string) => void;
  openChosenGood: (id: string) => void;
}) => {
  const [scrollY, setScrollY] = useState(0);
  const theme: any = useTheme();
  const { black } = theme.colors;
  const isEmpty = chosenGoods.length === 0;
  const listRef = useRef<any>(null);
  const scrollStep = 129;
  const maxScroll =
    chosenGoods.length > 3 ? (chosenGoods.length - 3) * scrollStep : 0;

  const scrollToTop = () => {
    listRef.current.scroll({
      top: scrollY - scrollStep,
      left: 0,
      behavior: "smooth",
    });
  };
  const scrollToBottom = () => {
    listRef.current.scroll({
      top: scrollY + scrollStep,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Wrapper
      className={isOpenChosenGoods ? `open ${isEmpty ? "empty" : ""}` : ""}
      onScroll={(e: any) => setScrollY(e.target.scrollTop)}
      ref={listRef}
      height={`${chosenGoods.length * scrollStep}px`}
    >
      {isEmpty ? (
        <Text type={TextType.TEXT_16_BOLD} color={black}>
          {"Нет избранных товаров..."}
        </Text>
      ) : (
        chosenGoods.map((good: GoodType, index: number) => {
          return (
            <ChosenItem
              key={index}
              className={index === chosenGoods.length - 1 ? "last" : ""}
              data={good}
              deleteChosenGood={deleteChosenGood}
              openChosenGood={openChosenGood}
            />
          );
        })
      )}
      <Controllers
        chosenGoods={chosenGoods}
        scrollValue={scrollY}
        maxScroll={maxScroll}
        topOnClick={scrollToTop}
        bottomOnClick={scrollToBottom}
      />
    </Wrapper>
  );
};
