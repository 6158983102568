import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { selectChosenGoods } from "../../app/state/chosenGoodsSlice";
import { Text, TextType } from "../../components/text/Text";
import { View } from "../../routes";
import { media } from "../../styles/media";
import { useWindowSize } from "../../styles/style.context";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { CommonButton } from "../commonButton/CommonButton";
import { CommonButtonLink } from "../commonButtonLink/commonButtonLink";
import { Logo, LogoType } from "../logo/Logo";
import { Burger } from "./components/burger/Burger";
import { ChoosenGoods } from "./components/choosenGoods/ChoosenGoods";
import { ChosenGoodsModal } from "./components/chosenGoodsModal/ChosenGoodsModal";
import { Menu, MenuArrType } from "./components/menu/Menu";
import { MobileMenu } from "./components/mobileMenu/MobileMenu";

interface WrapperType {
  backgroundColor?: string;
  borderColor?: string;
  withLargeLogo?: boolean;
}
const Wrapper = styled.div<WrapperType>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  padding: 0;
  border-bottom: 1px solid;
  border-color: ${(props: any) =>
    props.borderColor ? props.borderColor : props.theme.colors.gray4};
  transition: all 0.2s linear;
  position: fixed;
  top: 0;
  z-index: 1000;
  & > div:first-child {
    height: 88px;
  }

  &.hidden_logo {
    padding: 0;
  }
  ${media.desktop`
    padding: 0 25px;
    &.hidden_logo {
      padding: 0 25px;
    }  
    & > div:first-child {
    height: 80px;
  }
  `}
`;
export const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.withPadding {
    padding: 0 64px;
  }
  ${media.desktop`
    &.withPadding {
      padding: 0;
    }
  `}
`;
const LargeLogoWrapper = styled.div`
  width: 100%;
  height: 0;
  max-width: 1440px;
  display: flex;
  box-sizing: border-box;
  padding: 0 64px;
  opacity: 0;
  transition: all 0.3s linear;
  & > div {
    width: 100%;
  }
  &.visible {
    height: 162px;
    opacity: 1;
  }
  ${media.desktop`
    display: none;
  `}
`;
const PhoneNumberWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  & > span {
    width: max-content;
  }
  ${media.desktop`
    & > span {
      display: none;
    }
  `}
`;
const StyledLogo = styled(Logo)`
  margin: 0 64px;
  transition: all 0.3s linear;
  cursor: auto;
  pointer-events: none;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;

  &.disabled {
    cursor: auto;
    pointer-events: none;
  }
  ${media.desktop`
    margin: 0;
  `}
`;
const ShopButton = styled(CommonButtonLink)<any>`
  &:hover > div {
    background-color: ${(props: any) =>
      props.withLargeLogo
        ? props.theme.colors.black
        : props.theme.colors.white};
  }
  ${media.desktop`
    display: none;
  `}
`;
const LikedGoods = styled(ChoosenGoods)`
  display: none;
  ${media.desktop`
    display: flex;
  `}
`;
const Circle = styled.div<any>`
  width: 10px;
  height: 10px;
  background-color: ${(props: any) =>
    props.withLargeLogo ? props.theme.colors.white1 : props.theme.colors.black};
  margin-right: 8px;
  border-radius: 50%;
  transition: all 0.3s linear;
`;

export const Header = ({
  menuArr,
  deleteChosenGood,
  backgroundColor,
  borderColor,
}: {
  menuArr: MenuArrType[];
  deleteChosenGood: (id: string) => void;
  backgroundColor?: string;
  borderColor?: string;
}) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenChosenGoodsModal, setIsOpenChosenGoodsModal] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const theme: any = useTheme();
  const history = useCustomHistory();
  const { black, white1 } = theme.colors;
  const currentPath = useLocation().pathname;
  const windowWidth = useWindowSize()[0];
  const isLargeLogHidden = scrollY > 100;
  const withLargeLogo = false;
  const isLogoDispayed =
    windowWidth > 820 ? !withLargeLogo || isLargeLogHidden : true;
  const chosenGoodsLocal = useSelector(selectChosenGoods);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  const burgerOnClick = () => {
    setIsOpenMobileMenu(!isOpenMobileMenu);
  };
  const openChosenGoodsModal = () => {
    setIsOpenChosenGoodsModal(true);
  };
  const closeChosenGoodsModal = () => {
    setIsOpenChosenGoodsModal(false);
  };
  const openChosenGood = (id: string) => {
    console.log("reached");
    history.push(View.PRODUCTS_DETAILS, { pathParams: { id } });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Wrapper
        theme={theme}
        className={`hidden_logo ${scrollY > 0 ? "scrolled" : ""}`}
        withLargeLogo={withLargeLogo}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      >
        <CenteredWrapper className={"withPadding"}>
          <Burger
            onClick={burgerOnClick}
            isOpenMobileMenu={isOpenMobileMenu}
            withLargeLogo={withLargeLogo}
          />
          <Menu menuArr={menuArr} currentPath={currentPath} />
          <StyledLogo
            className={`${currentPath.includes("main") ? "disabled" : ""}`}
            type={LogoType.SMALL}
            color={`${currentPath.includes("main") ? white1 : black}`}
            onClick={() => {
              history.push(View.MAIN);
            }}
          />
          <PhoneNumberWrapper>
            <Text
              type={TextType.TEXT_14_REGULAR}
              color={`${currentPath.includes("main") ? white1 : black}`}
              margin={"0 64px 0 0"}
            >
              {"+375 (44) 799-70-70"}
            </Text>
            <ShopButton
              onClick={() => {
                console.log("Shop");
              }}
              href={"https://shop.positano.by/"}
              value={"МАГАЗИН"}
              width={"118px"}
              height={"32px"}
              backgroundColor={"transparent"}
              borderColor={`${currentPath.includes("main") ? white1 : black}`}
              color={`${currentPath.includes("main") ? white1 : black}`}
              colorHover={`${currentPath.includes("main") ? black : white1}`}
              borderColorHover={`${
                currentPath.includes("main") ? black : white1
              }`}
              backgroundColorHover={`${
                currentPath.includes("main") ? white1 : black
              }`}
              withLargeLogo={currentPath.includes("main")}
            ></ShopButton>
            <LikedGoods
              onClick={openChosenGoodsModal}
              withLargeLogo={withLargeLogo}
              count={chosenGoodsLocal.length}
            />
          </PhoneNumberWrapper>
          <MobileMenu
            menuArr={menuArr}
            isOpenMobileMenu={isOpenMobileMenu}
            currentPath={currentPath}
            burgerOnClick={burgerOnClick}
          />
          <ChosenGoodsModal
            chosenGoods={chosenGoodsLocal}
            isOpenModal={isOpenChosenGoodsModal}
            closeModal={closeChosenGoodsModal}
            deleteChosenGood={deleteChosenGood}
            openChosenGood={openChosenGood}
          />
        </CenteredWrapper>
      </Wrapper>
      {/*withLargeLogo && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: "120px",
            left: "0",
          }}
        >
          <LargeLogoWrapper className={isLargeLogHidden ? "" : "visible"}>
            <Logo type={LogoType.LARGE} color={white1} />
          </LargeLogoWrapper>
        </div>
        )*/}
    </>
  );
};
