import styled from "styled-components";
import { LikeButton } from "../../../../general/main/components/mainDiscounts/components/likeButton/LikeButton";
import { media } from "../../../../../styles/media";

const ImageWrapper = styled.div`
  width: 50%;
  height: 808px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: any) => props.theme.colors.white1};
  position: relative;
  margin-right: 32px;

  ${media.desktop`
    width: 100%;
  `}
  ${media.tablet`
    height: 488px;
  `}
`;
const LocalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
const Like = styled(LikeButton)`
  background-color: white;
  right: 24px;
`;

export const Image = ({
  img,
  isChosen,
  removeItemFromChosenGoods,
  addItemToChosenGoods,
  ...props
}: {
  img: string;
  isChosen: boolean;
  removeItemFromChosenGoods: () => void;
  addItemToChosenGoods: () => void;
  className?: string;
}) => {
  return (
    <ImageWrapper {...props}>
      <LocalImage src={img} alt={"IMG"} />
      <Like
        onClick={isChosen ? removeItemFromChosenGoods : addItemToChosenGoods}
        isChoosen={isChosen}
      />
    </ImageWrapper>
  );
};
