import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { GeneralGoodsType } from "./goodsInterface";

const initialState: GeneralGoodsType = {
  searchbarValue: "",
  isLoadingDataInProcess: false,
};

export const goodsSlice = createSlice({
  name: "goods",
  initialState,
  reducers: {
    setSearchbarValue: (state, action: PayloadAction<any>) => {
      state.searchbarValue = action.payload.value;
    },
    setIsLoadingData: (state, action: PayloadAction<any>) => {
      state.isLoadingDataInProcess = action.payload.value;
    },
  },
});

export const { setSearchbarValue, setIsLoadingData } = goodsSlice.actions;

export const selectSearchbarValue = (state: RootState) => {
  return state.goods.searchbarValue;
};
export const selectIsLoadingDataInProcess = (state: RootState) => {
  return state.goods.isLoadingDataInProcess;
};

export default goodsSlice.reducer;
