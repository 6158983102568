import styled, { useTheme } from "styled-components";
import { GoodType } from "../../../../../api/products";
import { LoaderBlock } from "../../../../../components/loaderBlock/LoaderBlock";
import { Text, TextType } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { DataList } from "../../../../general/main/components/mainDiscounts/components/dataList/DataList";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px 144px 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  margin-bottom: 120px;
  position: relative;
  ${media.desktop`
    padding: 0 24px 136px 24px;
    & > span {
      font-size: 40px;
      line-height: 48px;
    }
  `}
`;

const Placeholder = styled(Text)`
  text-align: center;
`;

export const CollectionProductsList = ({
  data,
  dataItemOnClick,
  addItemToChosenGoods,
  removeItemFromChosenGoods,
  isLoadingDataInProcess,
}: {
  data: any[];
  dataItemOnClick: (id: string) => void;
  addItemToChosenGoods: (item: GoodType) => void;
  removeItemFromChosenGoods: (id: string) => void;
  isLoadingDataInProcess?: boolean;
}) => {
  const theme: any = useTheme();
  const { black, badgeMiddle } = theme.colors;

  return (
    <Wrapper>
      <Text type={TextType.TITLE_H1} margin={"0 0 32px 0"}>
        {"Продукты коллекции"}
      </Text>
      {data && data.length > 0 ? (
        <DataList
          data={data}
          dataItemOnClick={dataItemOnClick}
          addItemToChosenGoods={addItemToChosenGoods}
          removeItemFromChosenGoods={removeItemFromChosenGoods}
          withBigItems
          dataItemTitleColor={black}
        />
      ) : (
        <Placeholder type={TextType.TITLE_H2} color={badgeMiddle}>
          {"Нет данных"}
        </Placeholder>
      )}
      {isLoadingDataInProcess && <LoaderBlock />}
    </Wrapper>
  );
};
