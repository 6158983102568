// @ts-ignore
import styled, { useTheme } from "styled-components";
import { BrandType } from "../../Brands";
import { Badge } from "./components/badge/Badge";
import { Text, TextType } from "../../../../../components/text/Text";
import { CommonButton } from "../../../../../components/commonButton/CommonButton";

import { View, getPath } from "../../../../../routes";
import { media } from "../../../../../styles/media";
import { ReactComponent as Arrow } from "../../../../../assets/large_arrow.svg";
import { useState } from "react";
import { injectPathParams } from "../../../../../routes/paths";
import { stringifyUrl } from "query-string";
import { useHistory } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  ${media.tablet`
    box-sizing: border-box;
    flex-direction: column;
    padding: 40px 24px;
    border-bottom: 1px solid;
    border-color: ${(props: any) => props.theme.colors.badgeDark};
  `}
`;
export const ImageWrapper = styled.div`
  width: 448px;
  height: 448px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  padding: 64px 32px;
  & > div.badge {
    display: none;
  }
  ${media.tablet`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: max-content;
    border: none;
    padding: 0;
    & > div.badge {
       display: flex;
       margin-bottom: 24px;
    }
  `}
`;
const Image = styled.img`
  ${media.phone`
    width: 100%;
  `}
`;
const InfoWrapper = styled.div`
  width: calc(100% - 448px);
  height: 448px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  padding: 64px 32px;
  ${media.tablet`
    width: 100%;
    margin-top: 24px;
    padding: 0;
    border: none;
    height: max-content;
    & > div.badge {
        display: none;
    }
  `}
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;
  ${media.tablet`
    margin-top: 24px;
  `}
  ${media.phone`
    height: 0;
    overflow: hidden;
    margin: 0;
    opacity: 0;
    & > button {
        width: 100%;
    }
    &.open {
        margin-top: 24px;
        height: max-content;
        opacity: 1;
    }
  `}
`;
const LinkWrapper = styled.div`
  display: flex;
`;
const Link = styled.a`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: any) => props.theme.colors.greenDark};
  margin-left: 4px;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    opacity: 0.7;
  }
`;
const Description = styled(Text)`
  max-height: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.tablet`
    max-height: none;
    margin: 24px 0 40px 0;
  `}
`;
const NameWrapper = styled.div`
  display: flex;
  & > span.name {
    max-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${media.tablet`
    width: 100%;
    justify-content: space-between;
    & > span.name {
        width: calc(100% - 78px);
        max-height: none;
        margin: 0;
    }
`}
`;
const ArrowIcon = styled(Arrow)<any>`
  display: none;
  ${media.tablet`
    display: inline-block;
    transform: rotate(0deg);
    transition: all 0.3s linear;
    &.open {
        transform: rotate(180deg);
    }
  `}
`;
const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

export const BrandsItem = ({
  data,
  ...props
}: {
  data: BrandType;
  className?: string;
}) => {
  const [isInfoBlockOpen, setIsInfoBlockOpen] = useState(false);
  const theme: any = useTheme();
  const history = useHistory();
  const { badgeMiddle, black, greenDark } = theme.colors;
  const { image, name, description, website, sections } = data;
  const preparedDesc =
    description.length > 310
      ? description.substring(0, 310) + "..."
      : description;

  const showGoodsOnClick = () => {
    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(View.PRODUCTS), {
          section: sections[0],
          limit: 12,
        }),
        query: { brand: data.id },
      })
    );
  };

  const nameOnClick = () => {
    setIsInfoBlockOpen(!isInfoBlockOpen);
  };

  return (
    <Wrapper {...props}>
      <ImageWrapper theme={theme}>
        <BadgeWrapper className={"badge"}>
          {sections.map((item: string) => (
            <Badge product_id={item} />
          ))}
        </BadgeWrapper>

        <Image src={image} alt={"IMG"} />
      </ImageWrapper>
      <InfoWrapper>
        <BadgeWrapper className={"badge"}>
          {sections.map((item: string) => (
            <Badge product_id={item} />
          ))}
        </BadgeWrapper>
        <NameWrapper onClick={nameOnClick}>
          <Text
            className={"name"}
            type={TextType.TITLE_H2}
            color={black}
            margin={"28px 0 16px 0"}
          >
            {name}
          </Text>
          <ArrowIcon className={isInfoBlockOpen ? "open" : ""} />
        </NameWrapper>
        <Container className={isInfoBlockOpen ? "open" : ""}>
          <LinkWrapper>
            <Text type={TextType.TEXT_16_REGULAR} color={greenDark}>
              {"Веб сайт – "}
            </Text>
            <Link
              theme={theme}
              href={`https://${website}/`}
              target={"_blank"}
            >{` ${website}`}</Link>
          </LinkWrapper>
          <Description
            type={TextType.TEXT_16_REGULAR}
            color={greenDark}
            margin={"16px 0 32px 0"}
          >
            {preparedDesc}
          </Description>
          <CommonButton
            value={"посмотреть товары".toUpperCase()}
            backgroundColor={black}
            backgroundColorHover={"transparent"}
            color={badgeMiddle}
            colorHover={black}
            borderColor={black}
            borderColorHover={black}
            onClick={showGoodsOnClick}
          />
        </Container>
      </InfoWrapper>
    </Wrapper>
  );
};
