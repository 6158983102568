import styled, { useTheme } from "styled-components";
import { CommonButton } from "../../../../../components/commonButton/CommonButton";
import { CommonButtonLink } from "../../../../../components/commonButtonLink/commonButtonLink";
import { Text, TextType } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { Image } from "../image/Image";
import { InfoItemType, InfoList } from "./components/infoList/InfoList";
import { Status } from "./components/status/Status";

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    width: 100%;
  `}
`;
const StyledText = styled(Text)`
  ${media.desktop`
    padding: 0 24px;
  `}
  ${media.tabletS`
    word-break: break-word;
  `}
`;
const Title = styled(StyledText)`
  ${media.desktop`
    font-size: 40px;
    line-height: 48px;
  `}
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  ${media.monitor`
    & > button {
      width: 48%;
    }
  `}
  ${media.desktop`
    padding: 0 24px;
  `}
  ${media.tablet`
    flex-direction: column;
    gap: 16px;
    & > button {
      width: 100%;
    }
  `}
`;
const MobileImage = styled(Image)`
  display: none;
  ${media.desktop`
    display: flex;
    padding: 24px;
    box-sizing: border-box;
  `}
`;
const Prices = styled(StyledText)`
  ${media.tablet`
    margin: 40px 0 88px 0;
  `}
`;

const StyledLink = styled(CommonButtonLink)`
  font-size: 14px;
  font-family: "Inter";
`;

const StyledButton = styled(CommonButton)`
  font-size: 14px;
  font-family: "Inter";
`;

export const Info = ({
  name,
  vendorCode,
  status,
  infoArr,
  img,
  isChosen,
  goToShopOnClick,
  orderConsultationOnClick,
  addItemToChosenGoods,
  removeItemFromChosenGoods,
  url,
}: {
  name: string;
  vendorCode: string;
  status: number;
  infoArr: InfoItemType[];
  img: string;
  isChosen: boolean;
  goToShopOnClick: () => void;
  orderConsultationOnClick: () => void;
  addItemToChosenGoods: () => void;
  removeItemFromChosenGoods: () => void;
  url?: string;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle, black, white } = theme.colors;

  return (
    <Wrapper>
      <Title type={TextType.TITLE_H1} margin={"0 0 24px 0"}>
        {name}
      </Title>
      <StyledText
        type={TextType.TEXT_16_REGULAR}
        color={badgeMiddle}
        margin={"0 0 8px 0"}
      >
        {`Артикул: ${vendorCode}`}
      </StyledText>
      <Status status={status} />
      <MobileImage
        img={img}
        isChosen={isChosen}
        addItemToChosenGoods={addItemToChosenGoods}
        removeItemFromChosenGoods={removeItemFromChosenGoods}
      />
      <InfoList arr={infoArr} />
      <ButtonsWrapper>
        <StyledLink
          value={"ПЕРЕЙТИ В МАГАЗИН"}
          onClick={goToShopOnClick}
          width={"312px"}
          href={url || ""}
          backgroundColor={black}
          color={white}
          borderColor={black}
          backgroundColorHover={"transparent"}
          colorHover={black}
          borderColorHover={black}
        />
        <StyledButton
          value={"ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ"}
          onClick={orderConsultationOnClick}
          width={"312px"}
          backgroundColor={"transparent"}
          color={black}
          borderColor={black}
          backgroundColorHover={black}
          colorHover={white}
          borderColorHover={black}
        />
      </ButtonsWrapper>
      <Prices
        type={TextType.TEXT_16_REGULAR}
        margin={"16px 0 0 0"}
        color={badgeMiddle}
      >
        {"*Цены доступны в онлайн-магазине"}
      </Prices>
    </Wrapper>
  );
};
