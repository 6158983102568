import { isArray } from "underscore";
import {
  FilterAction,
  FilterActionType,
  FilterMode,
  FilterState,
} from "./types";

const handleSingleChange = (
  state: FilterState,
  payload: FilterAction["payload"]
) => {
  const { mode, id, filterKey } = payload || {};
  const currentModeState = state.filters[mode as FilterMode];

  const currentItemIndex = currentModeState.active.findIndex(
    (item) => item.filterKey === filterKey
  );

  // if wrong filterKey was passed - return original state
  if (currentItemIndex === -1) {
    return state;
  }

  const currentItemState = currentModeState.active[currentItemIndex];
  const options = currentItemState.options.map((option) => ({
    ...option,
    isSelected: false,
  }));
  const optionIndex = options.findIndex((option) => option.id === id);
  let newOptions;
  if (optionIndex !== -1) {
    const currentOption = options[optionIndex];
    newOptions = [
      ...options.slice(0, optionIndex),
      { ...currentOption, isSelected: true },
      ...options.slice(optionIndex + 1),
    ];
  }

  return {
    filters: {
      ...state.filters,
      [mode as FilterMode]: {
        ...currentModeState,
        active: [
          ...currentModeState.active.slice(0, currentItemIndex),
          {
            ...currentItemState,
            options: newOptions || options,
          },
          ...currentModeState.active.slice(currentItemIndex + 1),
        ],
      },
    },
  };
};

const handleMultiChange = (
  state: FilterState,
  payload: FilterAction["payload"]
) => {
  const { mode, id, filterKey } = payload || {};
  const currentModeState = state.filters[mode as FilterMode];

  const currentItemIndex = currentModeState.active.findIndex(
    (item) => item.filterKey === filterKey
  );

  // if wrong filterKey was passed - return original state
  if (currentItemIndex === -1) {
    return state;
  }

  const currentItemState = currentModeState.active[currentItemIndex];
  const { options } = currentItemState;
  const optionIndex = options.findIndex((option) => option.id === id);
  let newOptions;
  if (optionIndex !== -1) {
    const currentOption = options[optionIndex];
    newOptions = [
      ...options.slice(0, optionIndex),
      { ...currentOption, isSelected: !currentOption.isSelected },
      ...options.slice(optionIndex + 1),
    ];
  }

  return {
    filters: {
      ...state.filters,
      [mode as FilterMode]: {
        ...currentModeState,
        active: [
          ...currentModeState.active.slice(0, currentItemIndex),
          {
            ...currentItemState,
            options: newOptions || options,
          },
          ...currentModeState.active.slice(currentItemIndex + 1),
        ],
      },
    },
  };
};

const examineQueryFilterOptionSelection = ({
  query = {},
  filterKey,
  id,
  filterType,
}: {
  query: Record<string, string | string[]>;
  filterKey: string;
  id: string;
  filterType: "single-select" | "multi-select" | "color-multi-select";
}) => {
  const queryFilter = query[filterKey];
  let isSelected = false;

  if (!!queryFilter) {
    const isOptionOfArrayType = isArray(queryFilter);

    const isFilterOfArrayType = filterType !== "single-select";
    isSelected = isOptionOfArrayType
      ? queryFilter.includes(id)
      : queryFilter === id;
  }
  return isSelected;
};

const handleInit = (state: FilterState, payload: FilterAction["payload"]) => {
  const { mode, filters, query } = payload || {};

  const readyFilters = (filters || []).map(
    ({ options, filterKey, filterType, ...filter }) => {
      let matchedWithQuery = false;

      return {
        ...filter,
        filterKey,
        filterType,
        options: options.reduce(
          (accumulator, { id, isSelected, ...option }, index) => {
            const isSelectedFromQuery = examineQueryFilterOptionSelection({
              query: query || {},
              filterKey,
              filterType,
              id,
            });
            if (isSelectedFromQuery) {
              matchedWithQuery = isSelectedFromQuery;
            }

            return [
              ...accumulator.map(
                ({ isSelected, isNotMatchingQuery, ...accOption }: any) => ({
                  ...accOption,
                  isSelected:
                    matchedWithQuery && isNotMatchingQuery ? false : isSelected,
                })
              ),
              matchedWithQuery
                ? { ...option, id, isSelected: isSelectedFromQuery }
                : index !== options.length - 1
                ? {
                    ...option,
                    id,
                    isSelected: !!isSelected,
                    isNotMatchingQuery: !isSelectedFromQuery,
                  }
                : {
                    ...option,
                    id,
                    isSelected: !!isSelected,
                  },
            ];
          },
          [] as typeof options
        ),
      };
    }
  );

  return {
    filters: {
      ...state.filters,
      [mode as FilterMode]: { active: readyFilters, default: filters },
    },
  };
};

const handleReset = (state: FilterState, payload: FilterAction["payload"]) => {
  const { mode } = payload || {};

  return {
    filters: {
      ...state.filters,
      [mode as FilterMode]: {
        ...state.filters[mode as FilterMode],
        active: state.filters[mode as FilterMode].default,
      },
    },
  };
};

export const reducer = (state: FilterState, action: FilterAction) => {
  switch (action.type) {
    case FilterActionType.HANDLE_SINGLE_CHANGE:
      return handleSingleChange(state, action.payload);

    case FilterActionType.HANDLE_MULTI_CHANGE:
      return handleMultiChange(state, action.payload);

    case FilterActionType.INIT_FILTERS:
      return handleInit(state, action.payload);

    case FilterActionType.RESET:
      return handleReset(state, action.payload);

    default:
      throw new Error();
  }
};
