import { API_URL } from "../consts";
import { useApi } from "../features/apiBuilder";
import { Filter } from "../features/filters/types";

export const FILTERS_KEYWORD = "filters";

export const useGetFiltersList = (
  scope: "products" | "collections",
  handleSuccess: (data: Filter[]) => void
) => {
  const [localData] = useApi({
    url: `${API_URL}/filters?scope=${scope}`,
    keywords: [FILTERS_KEYWORD],
    transformResponse: ({ payload }) => payload,
    handleSuccess: (transformedData) =>
      handleSuccess(transformedData as Filter[]),
  });

  return localData;
};
