import { Dispatch } from "react";

export enum FilterActionType {
  HANDLE_SINGLE_CHANGE = "HANDLE_SINGLE_CHANGE",
  HANDLE_MULTI_CHANGE = "HANDLE_MULTI_CHANGE",
  RESET = "RESET",
  INIT_FILTERS = "INIT_FILTERS",
}

export type FilterMode = "products" | "collections";

export type Filter = {
  filterKey: string;
  filterType: "single-select" | "multi-select" | "color-multi-select";
  label: string;
  options: {
    name: string;
    id: string;
    isSelected?: boolean;
    hex?: string;
    contrastColor?: string;
  }[];
};

export type FilterState = {
  filters: { [key in FilterMode]: { active: Filter[]; default: Filter[] } };
};

export type FilterAction = {
  type: FilterActionType;
  payload?: {
    mode: FilterMode;
    filterKey?: string;
    id?: string;
    filters?: Filter[];
    query?: Record<string, string[] | string>;
  };
};

export type FilterFunctions = {
  onSingleChange: (id: string, mode: FilterMode, filterKey: string) => void;
  onMultiChange: (id: string, mode: FilterMode, filterKey: string) => void;
  initFilters: (mode: FilterMode, filters: Filter[]) => void;
  reset: (mode: FilterMode) => void;
  getActiveFilters: (mode: FilterMode) => Filter[];
};

export type FilterContextProviderType = [
  FilterState,
  Dispatch<FilterAction>,
  FilterFunctions
];
