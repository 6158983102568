// @ts-ignore
import { ReactNode } from "react";
import styled, { useTheme } from "styled-components";
import { SpinnerClassic } from "../../features/animations/SpinnerClassic";

const Button = styled.a<any>`
  text-decoration: none;
  width: ${(props: any) => (props.width ? props.width : "225px")};
  height: ${(props: any) => (props.height ? props.height : "40px")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  color: ${(props: any) =>
    props.color ? props.color : props.theme.colors.black};
  outline: none;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.borderColor
      ? props.borderColor
      : props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.black};
  cursor: pointer;
  position: relative;
  transition: all 0.3s linear;
  pointer-events: ${(props: any) =>
    props.isLoadingInProcess ? "none" : "all"};

  & > div > div:before {
    background-color: ${(props: any) =>
      props.color ? props.color : props.theme.colors.black};
  }

  &:hover {
    color: ${(props: any) =>
      props.colorHover ? props.colorHover : props.theme.colors.black};
    background-color: ${(props: any) =>
      props.backgroundColorHover
        ? props.backgroundColorHover
        : props.theme.colors.white1};
    border-color: ${(props: any) =>
      props.borderColorHover
        ? props.borderColorHover
        : props.backgroundColorHover
        ? props.backgroundColorHover
        : props.theme.colors.black};
    & > div > div:before {
      background-color: ${(props: any) =>
        props.colorHover ? props.colorHover : props.theme.colors.black};
    }
  }
`;
const Loader = styled(SpinnerClassic)`
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 122px;
`;

export const CommonButtonLink = ({
  value,
  onClick,
  width,
  height,
  backgroundColor,
  color,
  borderColor,
  backgroundColorHover,
  colorHover,
  borderColorHover,
  isLoadingInProcess,
  children,
  href,
  ...props
}: {
  value: string;
  onClick: () => void;
  width?: string;
  height?: string;
  backgroundColor?: string;
  color?: string;
  backgroundColorHover?: string;
  colorHover?: string;
  borderColor?: string;
  borderColorHover?: string;
  isLoadingInProcess?: boolean;
  children?: ReactNode;
  href: string;
  className?: string;
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      href={href}
      target={"_blank"}
      rel={"noopener noreferrer"}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      color={color}
      borderColor={borderColor}
      backgroundColorHover={backgroundColorHover}
      colorHover={colorHover}
      borderColorHover={borderColorHover}
      theme={theme}
      isLoadingInProcess={isLoadingInProcess}
      {...props}
    >
      {children && children}
      {value}
      {isLoadingInProcess && <Loader />}
    </Button>
  );
};
