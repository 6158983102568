import styled, { useTheme } from "styled-components";
import { Crumbs } from "../../../../components/crumbs/Crumbs";
import { SearchBar } from "../../../../components/searchBar/SearchBar";
import { Text, TextType } from "../../../../components/text/Text";
import { media } from "../../../../styles/media";
import { GoodsCollectionsSwitcher } from "../goodsCollectionsSwitcher/GoodsCollectionsSwitcher";
import { MobileFilters } from "../mobileFilters/MobileFilters";
import { PageOptions } from "../pageOptions/PageOptions";
import { useCurrentView } from "../../../../routes/currentView";

const TopWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px;
  ${media.tablet`
  padding: 0;
`}
`;
const SearchbarWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 32px 32px 32px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  ${media.tablet`
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 24px 20px 24px;
    & > span {
      display: none;
    }
  `}
`;

export const TopPart = ({
  pageIndicator,
  pageOptionsArr,
  currentSection,
  fullDataListLength,
  searchbarValue,
  isOpenMobileFiltersModal,
  goodsCollectionsSwitcherOnClick,
  pageOptionOnClick,
  searchbarOnChange,
  openFiltersModal,
  closeFiltersModal,
  readyButtonOnClickFiltersModal,
  resetFilterButtonOnClickFiltersModal,
}: {
  pageIndicator: string;
  pageOptionsArr: {
    name: string;
    id: string;
  }[];
  currentSection: string;
  fullDataListLength: number;
  searchbarValue: string;
  isOpenMobileFiltersModal: boolean;
  goodsCollectionsSwitcherOnClick: (isGoods: boolean) => void;
  pageOptionOnClick: (id: string) => void;
  searchbarOnChange: (value: string) => void;
  openFiltersModal: () => void;
  closeFiltersModal: () => void;
  readyButtonOnClickFiltersModal: () => void;
  resetFilterButtonOnClickFiltersModal: () => void;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle } = theme.colors;
  const collectionLabel =
    fullDataListLength === 0
      ? "коллекций"
      : fullDataListLength === 1
      ? "коллекция"
      : fullDataListLength === 2 ||
        fullDataListLength === 3 ||
        fullDataListLength === 4
      ? "коллекции"
      : "коллекций";
  const productsLabel =
    fullDataListLength === 0
      ? "товаров"
      : fullDataListLength === 1
      ? "товар"
      : fullDataListLength === 2 ||
        fullDataListLength === 3 ||
        fullDataListLength === 4
      ? "товара"
      : "товаров";
  const { isGoods } = useCurrentView();

  return (
    <>
      <TopWrapper>
        <Crumbs chapter={"Каталог"} pageIndicator={pageIndicator} />
        <GoodsCollectionsSwitcher onClick={goodsCollectionsSwitcherOnClick} />
        <PageOptions
          arr={pageOptionsArr}
          currentSection={currentSection}
          pageOptionOnClick={pageOptionOnClick}
        />
      </TopWrapper>
      <SearchbarWrapper>
        <Text
          type={TextType.TEXT_12_REGULAR}
          color={badgeMiddle}
          lineHeight={"12px"}
        >
          {`${fullDataListLength} ${isGoods ? productsLabel : collectionLabel}`}
        </Text>
        <SearchBar value={searchbarValue} onChange={searchbarOnChange} />
      </SearchbarWrapper>
      <MobileFilters
        isOpenMobileFiltersModal={isOpenMobileFiltersModal}
        openFiltersModal={openFiltersModal}
        closeFiltersModal={closeFiltersModal}
        readyButtonOnClick={readyButtonOnClickFiltersModal}
        resetFilterButtonOnClick={resetFilterButtonOnClickFiltersModal}
      />
    </>
  );
};
