import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 120px;
  ${media.desktop`
    padding: 0 24px;
    margin-bottom: 40px;
  `}
`;
const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: any) => props.color};
`;

export const Status = ({ status }: { status: number }) => {
  const theme: any = useTheme();
  const { green, orange } = theme.colors;

  const color = status === 1 ? green : orange;
  const text = status === 1 ? "В наличии" : "Под заказ";

  return (
    <Wrapper>
      <Dot color={color} />
      <Text type={TextType.TEXT_14_REGULAR} color={color} margin={"0 0 0 8px"}>
        {text.toUpperCase()}
      </Text>
    </Wrapper>
  );
};
