import { stringifyUrl } from "query-string";
import { useCallback, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryParams } from "../../utils/useQueryParams";

export const useSearch = () => {
  const { pathname } = useLocation();
  const [search, setSearch] = useState({ slow: "", actual: "" });

  const queryParams = useQueryParams();
  const history = useHistory();
  const timerId = useRef(null as any);

  const setSearchValue = useCallback(
    (searchValue: string) => {
      setSearch((value) => ({
        ...value,
        actual: searchValue,
      }));

      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        //if (searchValue.length > 2 || searchValue === "") {
        setSearch((value) => ({
          ...value,
          slow: searchValue,
        }));
        pathname &&
          history &&
          history.replace(
            stringifyUrl({
              url: pathname,
              query: { ...queryParams, search: searchValue },
            })
          );
        //}
      }, 700);
    },
    [timerId, pathname, queryParams]
  );

  return [search.actual, setSearchValue] as const;
};
