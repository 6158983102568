export enum GeneralView {
  MAIN = "MAIN",
  ABOUT_US = "ABOUT_US",
  CONTACTS = "CONTACTS",
  REDIRECT = "REDIRECT",
  COOPERATION = "COOPERATION",
  BRANDS = "BRANDS",
  PRODUCTS = "PRODUCTS",
  PRODUCTS_DETAILS = "PRODUCTS_DETAILS",
  COLLECTIONS = "COLLECTIONS",
  COLLECTIONS_DETAILS = "COLLECTIONS_DETAILS",
}
