import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoodType } from "../../api/products";

import { RootState } from "../store";
import { ChosenGoodsType } from "./chosenGoodsInterface";

const ls = window.localStorage;
const chosenGoods = JSON.parse(ls.getItem("chosenGoods") as string) || [];

const initialState: ChosenGoodsType = {
  goods: chosenGoods,
};

export const chosenGoodsSlice = createSlice({
  name: "chosenGoods",
  initialState,
  reducers: {
    addChosenGood: (state, action: PayloadAction<any>) => {
      const elem = action.payload.value;
      const goods = state.goods as GoodType[];
      const chosenIndex =
        goods.findIndex((item: GoodType) => item.id === elem.id) ||
        ([] as any[]);
      if (chosenIndex === -1) {
        state.goods = [...state.goods, elem];
      }
    },
    removeChosenGood: (state, action: PayloadAction<any>) => {
      state.goods = state.goods.filter(
        (good: GoodType) => good.id !== action.payload.id
      );
    },
  },
});

export const { addChosenGood, removeChosenGood } = chosenGoodsSlice.actions;

export const selectChosenGoods = (state: RootState) => {
  return state.chosenGoods.goods;
};

export default chosenGoodsSlice.reducer;
