import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { useGetBrands } from "../../../api/brands";
import {
  selectIsLoadingDataInProcess,
  setIsLoadingData,
} from "../../../app/state/goodsSlice";
import { LoaderBlock } from "../../../components/loaderBlock/LoaderBlock";
import { Text, TextType } from "../../../components/text/Text";
import { useScrollReset } from "../../../features/useScrollReset/useScrollReset";
import { General } from "../../../layouts/general/General";
import { media } from "../../../styles/media";
import { PageWrapper, Wrapper } from "../../general/contacts/Contacts";
import { BrandsItem } from "./components/brandsItem/BrandsItem";
import { Tail } from "./components/brandsItem/components/tail/Tail";

export interface BrandType {
  id: string;
  name: string;
  website: string;
  description: string;
  image: string;
  sections: string[];
}

const BrandsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
`;
const Placeholder = styled(Text)`
  width: 100%;
  text-align: center;
`;
export const Title = styled(Text)`
  ${media.tablet`
    font-size: 40px;
    line-height: 48px;
    margin: 120px 0px 40px 24px;
  `}
`;

export const Brands = ({ match }: { match: any }) => {
  const theme: any = useTheme();
  const { badgeMiddle, badgeDark, black } = theme.colors;
  const dispatch = useDispatch();
  const isLoadingDataInProcess = useSelector(selectIsLoadingDataInProcess);

  const setIsLoadingDataInProcess = (value: boolean) => {
    dispatch(setIsLoadingData({ value }));
  };
  const brandsList = useGetBrands(0, setIsLoadingDataInProcess);
  const currentPath = match.path;

  useEffect(() => {
    if (!isLoadingDataInProcess) {
      setIsLoadingDataInProcess(true);
    }
  }, []);

  useScrollReset();

  return (
    <General currentPath={currentPath}>
      <PageWrapper>
        <Wrapper borderColor={badgeDark}>
          <Title
            type={TextType.TITLE_H1}
            color={black}
            margin={"64px 0 64px 32px"}
          >
            {"Бренды"}
          </Title>
          {brandsList && brandsList.length > 0 ? (
            <BrandsWrapper theme={theme}>
              {brandsList.map((brand: BrandType, index: number) => (
                <BrandsItem key={index} data={brand} />
              ))}
              <Tail />
            </BrandsWrapper>
          ) : (
            <Placeholder type={TextType.TITLE_H2} color={black}>
              {"Нет данных"}
            </Placeholder>
          )}
          {isLoadingDataInProcess && <LoaderBlock />}
        </Wrapper>
      </PageWrapper>
    </General>
  );
};
