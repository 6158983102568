import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../components/text/Text";
import { media } from "../../../../styles/media";
import { useCurrentView } from "../../../../routes/currentView";

const Wrapper = styled.div`
  width: max-content;
  margin-bottom: 32px;
  ${media.tablet`

    margin: 120px 0 40px 24px;
    & > span {
      font-size: 24px;
      line-height: 24px;
    }
  `}
`;
const Title = styled(Text)`
  color: ${(props: any) => props.theme.colors.badgeMiddle};
  &:hover {
    color: ${(props: any) => props.theme.colors.black};
  }

  &.current {
    color: ${(props: any) => props.theme.colors.black};
    pointer-events: none;
  }
`;

export const GoodsCollectionsSwitcher = ({
  onClick,
}: {
  onClick: (isGoods: boolean) => void;
}) => {
  const theme: any = useTheme();
  const { badgeMiddle } = theme.colors;
  const { isGoods } = useCurrentView();

  return (
    <Wrapper>
      <Title
        className={isGoods ? "current" : ""}
        type={TextType.TITLE_H1}
        onClick={() => {
          onClick(true);
        }}
      >
        {"Товары"}
      </Title>
      <Text type={TextType.TITLE_H1} color={badgeMiddle}>
        {" / "}
      </Text>
      <Title
        className={!isGoods ? "current" : ""}
        type={TextType.TITLE_H1}
        onClick={() => {
          onClick(false);
        }}
      >
        {"Коллеции"}
      </Title>
    </Wrapper>
  );
};
