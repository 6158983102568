import styled, { useTheme } from "styled-components";
import { CheckBox } from "../../../../../../components/checkbox/Checkbox";
import { Text, TextType } from "../../../../../../components/text/Text";
import { ColorOption } from "./components/colorOption/ColorOption";

const Wrapper = styled.li`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &.narrow {
    width: max-content;
  }

  &.selected {
    & > span {
      font-weight: 600;
    }
  }

  &:hover {
    & > span {
      font-weight: 600;
    }
  }
`;

const StyledText = styled(Text)`
  transition: none;
  cursor: pointer;
`;

export const OptionItem = ({
  type,
  value,
  isSelected,
  onClick,
}: {
  type: string;
  value: any;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const theme: any = useTheme();
  const { black } = theme.colors;

  return (
    <Wrapper
      className={`${type === "color-multi-select" ? "narrow" : ""} ${
        isSelected ? "selected" : ""
      }`}
      onClick={onClick}
    >
      {type === "single-select" ? (
        <StyledText type={TextType.TEXT_16_REGULAR} color={black}>
          {value.name}
        </StyledText>
      ) : type === "multi-select" ? (
        <>
          <CheckBox handleToggle={(e: any) => {}} toggle={isSelected} />
          <StyledText
            type={TextType.TEXT_16_REGULAR}
            color={black}
            margin={"0 0 0 8px"}
          >
            {value.name}
          </StyledText>
        </>
      ) : type === "color-multi-select" ? (
        <ColorOption
          isSelected={isSelected}
          backgroundColor={value.hex}
          iconColor={value.contrastColor}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
