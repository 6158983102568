import { useState } from "react";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { GoodType, useGetProductsList } from "../../../api/products";
import {
  addChosenGood,
  removeChosenGood,
} from "../../../app/state/chosenGoodsSlice";

import { CommonButton } from "../../../components/commonButton/CommonButton";
import { useScrollReset } from "../../../features/useScrollReset/useScrollReset";
import { General } from "../../../layouts/general/General";
import { View } from "../../../routes/routeInterfaces";
import { media } from "../../../styles/media";
import { useCustomHistory } from "../../../utils/react-router-dom-abstraction";
import { PageWrapper } from "../contacts/Contacts";
import { Escort } from "./components/escort/Escort";
import { MainCatalog } from "./components/mainCatalog/MainCatalog";
import { MainDiscounts } from "./components/mainDiscounts/MainDiscounts";

export const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled(PageWrapper)`
  padding: 88px 0 0 0;
  ${media.tablet`
    padding: 80px 0 0 0;
  `}
`;
const WrapperWithBorder = styled.div`
  width: calc(100% - 64px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${(props: any) => props.theme.colors.greenDark};
  margin: 0 auto;
  ${media.monitor`
    padding: 0 32px;
  `}
  ${media.tablet`
    width: 100%;
    border: none;
    padding: 0 24px;
    margin-bottom: 120px;
  `}
`;
const ButtonWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: none;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 120px;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.greenDark};

  ${media.tabletS`
    display: flex;
  `}
  ${media.phone`
  padding: 0 24px 120px 24px;
    & > button {
      width: 100%;
    }
  `}
`;

export const Main = ({ match }: { match: any }) => {
  const offsetStep = 6;
  const [discountsOffset, setDiscountsOffset] = useState(offsetStep - 1);
  const currentPath = match.path;
  const theme: any = useTheme();
  const { black, greenDark, white1 } = theme.colors;
  const history = useCustomHistory();
  const dispatch = useDispatch();

  const { products: discounts, isLoading } = useGetProductsList(
    "discounts",
    offsetStep - 1
  );
  const commonCountOfDsicounts = useGetProductsList("discounts", 0).products
    ?.length;
  const isLoadMoreButtonDisplayed = discountsOffset < commonCountOfDsicounts;

  const loadMoreOnClick = () => {
    setDiscountsOffset(discountsOffset + offsetStep);
  };
  const discountItemOnClick = (id: string) => {
    history.push(View.PRODUCTS_DETAILS, { pathParams: { id } });
  };
  const addItemToChosenGoods = (item: GoodType) => {
    dispatch(addChosenGood({ value: item }));
  };
  const removeItemFromChosenGoods = (id: string) => {
    dispatch(removeChosenGood({ id }));
  };
  const showAllDiscounts = () => {
    history.push(View.PRODUCTS, {
      pathParams: { section: "discounts", limit: 12 },
    });
  };
  const goToCatalog = () => {
    history.push(View.PRODUCTS, {
      pathParams: { section: "tiles", limit: 12 },
    });
  };

  useScrollReset();

  return (
    <General
      currentPath={currentPath}
      backgroundColor={black}
      borderColor={greenDark}
    >
      <Wrapper withLargeLogo backgroundColor={black}>
        <CenteredWrapper>
          <Escort />
          <ButtonWrapper>
            <CommonButton
              value={"СМОТРЕТЬ КАТАЛОГ"}
              onClick={goToCatalog}
              width={"416px"}
              backgroundColor={white1}
              backgroundColorHover={"transparent"}
              color={black}
              colorHover={white1}
              borderColor={white1}
              borderColorHover={white1}
            />
          </ButtonWrapper>
          <WrapperWithBorder>
            <MainDiscounts
              data={discounts}
              discountItemOnClick={discountItemOnClick}
              loadMoreOnClick={loadMoreOnClick}
              addItemToChosenGoods={addItemToChosenGoods}
              removeItemFromChosenGoods={removeItemFromChosenGoods}
              showAllDiscounts={showAllDiscounts}
              goToCatalog={goToCatalog}
              isLoadingDataInProcess={isLoading}
              isLoadMoreButtonDisplayed={isLoadMoreButtonDisplayed}
            />
            <MainCatalog />
          </WrapperWithBorder>
        </CenteredWrapper>
      </Wrapper>
    </General>
  );
};
