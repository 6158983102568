// @ts-ignore
import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../components/text/Text";
import { ReactComponent as HeartIcon } from "../../../../assets/choosen_heart.svg";
import { media } from "../../../../styles/media";

const Wrapper = styled.div<any>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${media.tablet`
    position: relative;
    & > svg {
      width: 26px;
      height: 23px;
      & > path {
        fill: ${(props: any) =>
          props.withLargeLogo
            ? props.theme.colors.white1
            : props.theme.colors.gray1};
      }
    }
  `}
`;
const CountWrapper = styled.div<any>`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: any) =>
    props.withLargeLogo ? props.theme.colors.white1 : props.theme.colors.gray1};
  border-radius: 50%;
  margin-left: 4px;
  transition: all 0.3s linear;
  &.wide {
    width: 24px;
    height: 24px;
  }
  ${media.tablet`
    margin: 0;
    position: relative;
    top: -8px;
  `}
`;

export const ChoosenGoods = ({
  onClick,
  count,
  withLargeLogo,
  ...props
}: {
  onClick: () => void;
  count: number;
  withLargeLogo?: boolean;
  className?: string;
}) => {
  const theme: any = useTheme();
  const { white1, gray1 } = theme.colors;

  return (
    <Wrapper onClick={onClick} withLargeLogo={withLargeLogo} {...props}>
      <HeartIcon />
      <CountWrapper
        className={count > 9 ? "wide" : ""}
        withLargeLogo={withLargeLogo}
      >
        <Text
          type={TextType.TEXT_12_REGULAR}
          color={withLargeLogo ? gray1 : white1}
        >
          {count < 10 ? count : "9+"}
        </Text>
      </CountWrapper>
    </Wrapper>
  );
};
