import styled, { useTheme } from "styled-components";
import { CommonButton } from "../../../../../../../components/commonButton/CommonButton";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";

const Wrapper = styled.div`
  width: 416px;
  height: 524px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 32px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};

  ${media.tabletS`
    width: 100%;
    height: 342px;
    & > button {
        width: 100%;
    }
  `}
`;
const Title = styled(Text)`
  ${media.tablet`
        font-size: 40px;
        line-height: 40px;
    `}
`;

export const DiscountsPlaceholder = ({
  showButtonOnClick,
}: {
  showButtonOnClick: () => void;
}) => {
  const theme: any = useTheme();
  const { white, badgeMiddle, black } = theme.colors;

  return (
    <Wrapper>
      <Title type={TextType.TITLE_H1} margin={"0 0 24px 0"} color={white}>
        {"Уже скоро!"}
      </Title>
      <Text
        type={TextType.TEXT_16_REGULAR}
        margin={"0 0 32px 0"}
        color={badgeMiddle}
      >
        {"Здесь появятся товары по акционным ценам."}
      </Text>
      <CommonButton
        value={"ДРУГИЕ ТОВАРЫ"}
        onClick={showButtonOnClick}
        color={white}
        borderColor={white}
        backgroundColor={"transparent"}
        colorHover={black}
        borderColorHover={white}
        backgroundColorHover={white}
      />
    </Wrapper>
  );
};
