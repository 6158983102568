// @ts-ignore
import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../components/text/Text";
import { General } from "../../../layouts/general/General";
import { media } from "../../../styles/media";
import { PageWrapper, Wrapper } from "../../general/contacts/Contacts";
import { ReactComponent as ArrowRight } from "../../../assets/arrow_right.svg";
import { useScrollReset } from "../../../features/useScrollReset/useScrollReset";

interface ContactItemType {
  label: string;
  value: string;
  href: string;
  onClick: () => void;
}

const TitleWrapper = styled.div`
  width: 100%;
  max-width: 796px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 64px 32px;
  ${media.tablet`
    padding: 46px 32px 64px 32px;
  `}
`;
const Title = styled(Text)`
  ${media.tablet`
    font-size: 40px;
    line-height: 48px;
  `}
  ${media.phone`
    font-size: 32px;
    line-height: 32px;
  `}
`;
const DownPartWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 448px 1fr;
  border-top: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
`;
const EmptyPlaceholder = styled.div`
  ${media.tablet`
    display: none;
  `}
`;
const ContactWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 64px 32px 120px 32px;
  border-left: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  ${media.tablet`
    border: none;
    padding: 64px 24px 64px 24px;
  `}
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContactItem = styled.a`
  width: 352px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 32px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.black};
  border-radius: 24px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  &.first {
    margin-bottom: 16px;
  }
  & > svg {
    position: absolute;
    right: 32px;
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg {
      width: 22px;
      height: 22px;
      transition: all 0.3s linear;
      right: 28px;
    }
  }
  ${media.tablet`
    & > span.description {
      margin: 0 0 40px 0;
    }
  `}
  ${media.phone`
    width: 100%;
    justify-content: center;
    padding: 0;
    & > span.value {
      display: none;
    }
    & > svg {
      display: none;
    }
  `}
`;
const Tail = styled.div`
  width: 100%;
  height: 240px;
  border-top: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeDark};
  ${media.tablet`
    height: 120px;
  `}
`;

export const Cooperation = ({ match }: { match: any }) => {
  const theme: any = useTheme();
  const currentPath = match.path;
  const { gray3, badgeMiddle, black, badgeDark, greenDark } = theme.colors;
  const buttonsArr = [
    {
      label: "Написать",
      href: "mailto:positanogrupp@gmail.com",
      value: "positanogrupp@gmail.com",
      onClick: () => {},
    },
    {
      label: "Позвонить ",
      href: "tel:+375447997070",
      value: "+375 (44) 799-70-70",
      onClick: () => {},
    },
  ];

  useScrollReset();

  return (
    <General currentPath={currentPath}>
      <PageWrapper theme={theme}>
        <Wrapper>
          <TitleWrapper>
            <Title
              className={"title"}
              type={TextType.TITLE_H1}
              margin={"0 0 24px 0"}
              color={black}
            >
              {"Сотрудничество"}
            </Title>
            <Text
              type={TextType.TEXT_16_REGULAR}
              margin={"0 0 16px 0"}
              color={greenDark}
            >
              {
                "Фирма ПОЗИТАНО является Генеральным Дистрибьютором концерна PORCELANOSA Grupo – производителя элитной керамики №1 в Испании."
              }
            </Text>
            <Text
              type={TextType.TEXT_16_REGULAR}
              margin={"0"}
              color={greenDark}
            >
              {
                "И нет сомнений, что представители современных социальных резервов набирают популярность среди определенных слоев населения, а значит, должны быть в равной степени предоставлены сами себе. "
              }
            </Text>
          </TitleWrapper>
          <DownPartWrapper theme={theme}>
            <EmptyPlaceholder />
            <ContactWrapper theme={theme}>
              <Title
                type={TextType.TITLE_H1}
                margin={"0 0 24px 0"}
                color={black}
              >
                {"Свяжитесь с нами"}
              </Title>
              <Text
                className={"description"}
                type={TextType.TEXT_16_REGULAR}
                color={greenDark}
                margin={"0 0 40px 0"}
              >
                {"По всем интересующим Вас вопросам с нами можно связаться:"}
              </Text>
              <ButtonWrapper>
                {buttonsArr.map((button: ContactItemType, index: number) => {
                  const { label, value, href, onClick } = button;
                  return (
                    <ContactItem
                      key={index}
                      className={index === 0 ? "first" : ""}
                      href={href}
                      onClick={onClick}
                      theme={theme}
                    >
                      <Text
                        onClick={(e: any) => e.stopPropagation()}
                        color={black}
                        type={TextType.TEXT_14_REGULAR}
                      >
                        {label.toUpperCase()}
                      </Text>
                      <Text
                        onClick={(e: any) => e.stopPropagation()}
                        className={"value"}
                        type={TextType.TEXT_16_REGULAR}
                        color={gray3}
                        margin={"0 10px 0 10px"}
                      >
                        {value}
                      </Text>
                      <ArrowRight />
                    </ContactItem>
                  );
                })}
              </ButtonWrapper>
            </ContactWrapper>
          </DownPartWrapper>
          <Tail />
        </Wrapper>
      </PageWrapper>
    </General>
  );
};
