import styled, { useTheme } from "styled-components";
import { Text, TextType } from "../../../../../../../components/text/Text";

export interface CatalogItemType {
  title: string;
  img: string;
  to: { section: string; limit: number };
  width: string;
}
const Wrapper = styled.div<any>`
  width: ${(props: any) => props.width};
  height: 416px;
  box-sizing: border-box;
  background-image: url(${(props: any) => props.img});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 24px;
  cursor: pointer;

  &:hover {
    & > div {
      background-color: rgb(0, 0, 0, 0.3);
    }
  }
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.3s linear;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Title = styled(Text)`
  font-weight: 500;
`;

export const CatalogItem = ({
  data,
  onClick,
}: {
  data: CatalogItemType;
  onClick: () => void;
}) => {
  const theme: any = useTheme();
  const { white1 } = theme.colors;
  const { width, img, title } = data;
  return (
    <Wrapper width={width} img={img} onClick={onClick}>
      <Title type={TextType.TITLE_H2} weight={"500"} color={white1}>
        {title}
      </Title>
      <Background />
    </Wrapper>
  );
};
