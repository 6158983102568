import styled from "styled-components";
import { Text, TextType } from "../../../../../../../components/text/Text";
import { media } from "../../../../../../../styles/media";

const Wrapper = styled.div`
  width: 416px;
  height: 416px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.greenDark};
  cursor: pointer;

  &:hover {
    & > span {
      color: ${(props: any) => props.theme.colors.badgeDark};
      border-color: ${(props: any) => props.theme.colors.badgeDark};
    }
  }
  ${media.tablet`
    width: 342px;
    height: 342px;
  `}
  ${media.phone`
    width: 100%;
  `}
`;
const Message = styled(Text)`
  width: max-content;
  border-bottom: 1px solid;
  border-color: ${(props: any) => props.theme.colors.badgeMiddle};
  color: ${(props: any) => props.theme.colors.badgeMiddle};
`;

export const SeeAll = ({ onClick }: { onClick: () => void }) => {
  return (
    <Wrapper onClick={onClick}>
      <Message type={TextType.TEXT_14_REGULAR}>{"СМОТРЕТЬ ВСЕ"}</Message>
    </Wrapper>
  );
};
