import { SyntheticEvent } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../../../assets/close_icon.svg";

const Wrapper = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  margin-right: 8px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Button = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background-color: ${(props: any) => props.theme.colors.badgeMiddle};
  border-radius: 50%;
  position: absolute;
  top: -12px;
  left: -12px;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.badgeDark};
      transition: all 0.3s linear;
    }
  }
`;

export const GoodsImage = ({
  src,
  removeChosenGood,
}: {
  src: string;
  removeChosenGood: (e: SyntheticEvent<HTMLElement>) => void;
}) => {
  return (
    <Wrapper>
      <Image src={src} />
      <Button onClick={removeChosenGood}>{<CloseIcon />}</Button>
    </Wrapper>
  );
};
